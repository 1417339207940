import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';

import { AccountType } from '@/types/user';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { OnboardingRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { environment } from '@/environments/environment';
import { getNextOnboardingStep } from '@/utils/onboarding';

import FormRadioGroup from '@/components/form/FormRadioGroup';

import SplitMuiPage from '../common/SplitMuiPage';

export interface RouteState {
  accountType?: AccountType;
  autoSubmit?: boolean;
}

interface FormData {
  account_type: AccountType;
}

const OnboardingAccountType: React.FC = () => {
  const { user, session, refreshUser } = useAuth();
  const location = useLocation<RouteState>();
  const accountTypeFromState = useMemo(() => {
    if (!location.state?.accountType) return null;

    if (location.state.accountType === AccountType.PROPERTY_MANAGER)
      return AccountType.PROPERTY_MANAGER;

    if (location.state.accountType === AccountType.HOME_OWNER) return AccountType.HOME_OWNER;

    return null;
  }, [location.state?.accountType]);

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>({
    defaultValues: {
      account_type: accountTypeFromState ?? (user?.user_metadata.account_type as AccountType),
    },
  });

  const { onboardingProgress } = useOnboardingProgress();

  const history = useHistory();

  const [formError, setFormError] = useState<Error | null>(null);

  const setAccountTypeMutation = useMutation({
    mutationFn: async (data: FormData) => {
      await axios.post(`${environment.api}/user/account_type`, data, {
        headers: { Authorization: `Bearer ${session?.access_token}` },
      });

      return data;
    },
    onError: (error: AxiosError) => {
      setFormError((error?.response?.data ?? error) as Error);
    },
    onSuccess: data => {
      const nextStep = getNextOnboardingStep(OnboardingRoutes.accountTypeSelect, {
        ...onboardingProgress,
        hasAccountType: true,
        accountType: data.account_type,
      });

      refreshUser();

      history.replace(...nextStep);
    },
  });

  const onSubmit = async (data: FormData) => {
    await setAccountTypeMutation.mutate(data);
  };

  useEffect(() => {
    if (!accountTypeFromState) return;

    if (!location.state?.autoSubmit) return;

    setAccountTypeMutation.mutate({
      account_type: accountTypeFromState,
    });
  }, [accountTypeFromState, location.state?.autoSubmit, setAccountTypeMutation]);

  return (
    <SplitMuiPage
      isLoading={!!accountTypeFromState && !!location.state?.autoSubmit}
      sideContent={
        <Stack flex="1 1 100%" sx={{ p: 2 }}>
          <Box
            component="img"
            src="assets/images/onboarding-account-type.png"
            sx={{ width: 510, height: 430, maxWidth: '100%', maxHeight: '100%', m: 'auto' }}
          />
        </Stack>
      }
    >
      <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={3} width="100%">
        <Stack spacing={1.5} mb={1}>
          <Typography variant="h3">Who are you?</Typography>

          <Typography variant="body2">
            Let us know why you’re using our groundbreaking technology.
          </Typography>
        </Stack>

        <Stack spacing={2.5}>
          <FormRadioGroup
            options={[
              {
                label: <Typography variant="h6">I'm a landlord</Typography>,
                value: AccountType.PROPERTY_MANAGER,
              },
              {
                label: <Typography variant="h6">I'm a home owner</Typography>,
                value: AccountType.HOME_OWNER,
              },
            ]}
            error={!!errors.account_type}
            helperText={errors.account_type?.message?.toString()}
            {...register('account_type', {
              required: 'Account Type is required',
            })}
            control={control}
          />

          {!!formError && <FormHelperText error>{formError.message}</FormHelperText>}
        </Stack>

        <Stack spacing={1} mb={1}>
          <LoadingButton loading={isSubmitting} fullWidth variant="contained" type="submit">
            Next
          </LoadingButton>
        </Stack>
      </Stack>
    </SplitMuiPage>
  );
};

export default OnboardingAccountType;
