import { TPropertyLocation } from './propertyLocation';

export enum TPropertyManagerRole {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
}

export interface TPropertyManager {
  id: string;
  user_id: string;
  created_at: string;
  updated_at: string;
  full_name: string;
  email: string;
  phone: string;
  role: TPropertyManagerRole;
  is_emergency_contact: boolean;
}

export interface TPropertyManagerLocations {
  propertyManager: {
    id: number;
    locations: Array<{
      location: Pick<TPropertyLocation, 'id' | 'name' | 'phone' | 'email'>;
    }>;
  };
}
