import { Autocomplete, CircularProgress, TextField } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { forwardRef, useEffect, useState } from 'react';
import { Controller, useController } from 'react-hook-form';

import { environment } from '@env';

import { Feature, GeocodeAPIV6Response } from '@/types/mapbox';

import FormField, { FormFieldProps, getControllerProps, getFormFieldProps } from './FormField';

interface Props extends FormFieldProps {
  placeholder?: string;
}

const FormAddressAutocomplete = forwardRef<HTMLDivElement, Props>(
  ({ color = 'secondary', placeholder = 'Enter address', size, ...props }, ref) => {
    const {
      field: { value, onChange },
    } = useController({
      name: props.name,
      control: props.control,
      defaultValue: props.defaultValue,
    });
    const [innerValue, setInnerValue] = useState(value ?? props.defaultValue ?? '');
    const [isOpen, setIsOpen] = useState(false);
    const [options, setOptions] = useState<readonly Feature[]>([]);

    const fetchSuggestionsQuery = useQuery({
      enabled: innerValue.length > 2 && isOpen,
      queryKey: ['GET', 'suggestions', innerValue],
      queryFn: async () => {
        const response = await axios.get<GeocodeAPIV6Response>(
          'https://api.mapbox.com/search/geocode/v6/forward?',
          {
            params: new URLSearchParams({
              q: innerValue,
              access_token: environment.mapboxApiKey,
              autocomplete: 'true',
              country: 'US',
              format: 'geojson',
              language: 'en-US',
              limit: '5',
              types: 'address',
            }),
          }
        );

        return response.data;
      },
    });

    useEffect(() => {
      if (fetchSuggestionsQuery.isError || fetchSuggestionsQuery.isPending) {
        setOptions([]);
        return;
      }

      if (innerValue.length <= 2) {
        setOptions([]);
        return;
      }

      if (fetchSuggestionsQuery.isSuccess) {
        setOptions(fetchSuggestionsQuery.data.features ?? []);
      }
    }, [
      fetchSuggestionsQuery.isError,
      fetchSuggestionsQuery.isPending,
      fetchSuggestionsQuery.isSuccess,
      fetchSuggestionsQuery.data?.features,
      innerValue,
    ]);

    return (
      <FormField {...getFormFieldProps(props)} ref={ref}>
        <Controller
          {...getControllerProps(props)}
          render={({ field }) => (
            <Autocomplete
              open={isOpen}
              onOpen={() => setIsOpen(true)}
              onClose={() => setIsOpen(false)}
              onChange={(_, _value) => onChange(_value?.properties.full_address ?? '')}
              isOptionEqualToValue={(option, _value) =>
                option.properties.full_address === _value.properties.full_address
              }
              getOptionLabel={option => option.properties.full_address}
              options={options}
              loading={isOpen && fetchSuggestionsQuery.isPending}
              defaultValue={{
                // @ts-ignore
                properties: {
                  full_address: innerValue,
                },
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  {...field}
                  size={size}
                  placeholder={placeholder}
                  value={innerValue}
                  onChange={e => setInnerValue(e.target.value)}
                  slotProps={{
                    input: {
                      ...params.InputProps,
                      endAdornment: (
                        <>
                          {isOpen && fetchSuggestionsQuery.isPending && (
                            <CircularProgress color="inherit" size={20} />
                          )}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    },
                  }}
                />
              )}
            />
          )}
        />
      </FormField>
    );
  }
);

export default FormAddressAutocomplete;
