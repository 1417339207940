import { Dialog, DialogContent, DialogTitle, Stack } from '@mui/material';

import IconButton from '@/@mantis/components/@extended/IconButton';
import { XCloseIcon } from '@/assets/icons/XCloseIcon';

import TermsOfServiceContent from './TermsOfServiceContent';

interface Props {
  onClose: () => void;
}

const TermsOfServiceModal: React.FC<Props> = ({ onClose }) => (
  <Dialog open onClose={onClose} maxWidth="lg" fullWidth>
    <DialogTitle>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        Terms and Conditions
        <IconButton onClick={onClose}>
          <XCloseIcon />
        </IconButton>
      </Stack>
    </DialogTitle>

    <DialogContent>
      <TermsOfServiceContent />
    </DialogContent>
  </Dialog>
);

export default TermsOfServiceModal;
