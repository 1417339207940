export enum PropertyLocationType {
  SINGLE_FAMILY_HOME = 'SINGLE_FAMILY_HOME',
  MULTI_UNIT = 'MULTI_UNIT',
}

export enum PropertyLocationDeclaredUnitsCount {
  FROM_1_TO_5 = 'FROM_1_TO_5',
  FROM_6_TO_25 = 'FROM_6_TO_25',
  FROM_26_TO_100 = 'FROM_26_TO_100',
  FROM_101_TO_500 = 'FROM_101_TO_500',
  ABOVE_500 = 'ABOVE_500',
}

export type TPropertyLocation = {
  id: number;
  name: string;
  phone: string;
  email: string;
  address_text: string;
  createdAt: string;
  updatedAt: string;
  type: keyof typeof PropertyLocationType;
  declared_units_count?: PropertyLocationDeclaredUnitsCount;
  buildings?: Array<{
    _count: {
      units: number;
    };
  }>;
  _count?: {
    buildings: number;
  };
};

export type TPropertyLocationSearchPhoneNumberItem = {
  addressRequirements: string;
  beta: boolean;
  capabilities: {
    MMS?: boolean;
    SMS?: boolean;
    voice?: boolean;
    fax?: boolean;
  };
  friendlyName: string;
  isoCountry: string;
  lata: string;
  latitude: number;
  locality: string;
  longitude: number;
  phoneNumber: string;
  postalCode: string;
  rateCenter: string;
  region: string;
};
