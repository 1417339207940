import { Box, FormHelperText, Stack, Typography } from '@mui/material';
import { DataGrid, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { useCallback, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

import { environment } from '@env';

import {
  TPropertyLocation,
  TPropertyLocationSearchPhoneNumberItem,
} from '@/types/propertyLocation';
import { ExtractParams } from '@/types/router';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import NoRowsOverlay from '@/@mui/data-grid/NoRowsOverlay';
import { OnboardingRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { getNextOnboardingStep } from '@/utils/onboarding';

import FormTextField from '@/components/form/FormTextField';
import PropertyLocationPhoneNumberSelectModal from '@/components/propertyLocation/PropertyLocationPhoneNumberSelectModal';

import SplitMuiPage from '../common/SplitMuiPage';

interface FormData {
  contains: string;
}

const PropertyLocationPhoneCreatePage: React.FC = () => {
  const { session } = useAuth();
  const { id } = useParams<ExtractParams<OnboardingRoutes.propertyLocationPhoneCreate>>();
  const history = useHistory();
  const { onboardingProgress } = useOnboardingProgress();

  const [selectedPhoneNumberItem, setSelectedPhoneNumberItem] =
    useState<TPropertyLocationSearchPhoneNumberItem | null>(null);
  const [isPhoneNumberSelectModalOpen, setIsPhoneNumberSelectModalOpen] = useState(false);

  const {
    register,
    control,
    watch,
    formState: { errors },
  } = useForm<FormData>();

  const [formError, setFormError] = useState<Error | null>(null);

  const contains = watch('contains');

  const fetchPhoneNumbersQuery = useQuery({
    enabled: !!session && !!contains && contains.length > 1,
    queryKey: ['property-location', id, 'search-phone-number', contains, !!session],
    queryFn: async () => {
      setFormError(null);
      try {
        const response = await axios.get<TPropertyLocationSearchPhoneNumberItem[]>(
          `${environment.api}/property-location/${id}/search-phone-number`,
          {
            params: {
              contains,
            },
            headers: {
              Authorization: `Bearer ${session?.access_token}`,
            },
          }
        );

        return response.data;
      } catch (err) {
        setFormError(err);
      }
    },
  });

  const onSelectPhoneNumberClick = useCallback(
    (phoneNumberItem: TPropertyLocationSearchPhoneNumberItem) => {
      setSelectedPhoneNumberItem(phoneNumberItem);
      setIsPhoneNumberSelectModalOpen(true);
    },
    []
  );

  const onPhoneNumberSelected = useCallback(
    (propertyLocation: TPropertyLocation) => {
      history.replace(
        ...getNextOnboardingStep(OnboardingRoutes.propertyLocationPhoneCreate, {
          ...onboardingProgress,
          hasPhoneNumber: !!propertyLocation.phone,
        })
      );
    },
    [history, onboardingProgress]
  );

  const columns: GridColDef[] = useMemo(
    () => [
      { field: 'friendlyName', headerName: 'Phone Number', flex: 1 },
      {
        field: 'actions',
        headerName: '',
        sortable: false,
        align: 'right',
        headerAlign: 'right',
        width: 100,
        renderCell: (params: GridRenderCellParams<TPropertyLocationSearchPhoneNumberItem>) => (
          <LoadingButton
            aria-label="Select Phone Number"
            size="small"
            color="success"
            onClick={() => onSelectPhoneNumberClick(params.row)}
          >
            Select
          </LoadingButton>
        ),
      },
    ],
    [onSelectPhoneNumberClick]
  );

  const PhoneNumberListNoRowsOverlay = useCallback(
    () => (
      <NoRowsOverlay
        entityName="Phone Number"
        detailsText={
          'Search by digits, area code, prefix, phrases, or characters you want in your phone number.\nEnter at least 2 characters.'
        }
      />
    ),
    []
  );

  return (
    <SplitMuiPage
      isSideContentPermanent
      sideContent={
        <Box sx={{ flex: '1 0 400px', p: 2, width: '100%' }}>
          <DataGrid
            rows={fetchPhoneNumbersQuery.data ?? []}
            columns={columns}
            density="comfortable"
            disableColumnFilter
            disableColumnMenu
            disableEval
            disableRowSelectionOnClick
            loading={fetchPhoneNumbersQuery.isLoading}
            slots={{
              noRowsOverlay: PhoneNumberListNoRowsOverlay,
            }}
            slotProps={{
              loadingOverlay: {
                variant: 'skeleton',
                noRowsVariant: 'skeleton',
              },
            }}
            sx={{ maxwidth: '100%' }}
            getRowId={row => row.phoneNumber}
            getRowHeight={() => 'auto'}
          />
        </Box>
      }
    >
      <Stack component="form" spacing={3} width="100%">
        <Stack spacing={1.5} mb={1}>
          <Typography variant="h3">Create contact details by number</Typography>

          <Typography variant="body2">
            Lorem ipsum dolor sit amet consectetur. Et arcu imperdiet dictum sed lectus urna purus
            at.
          </Typography>
        </Stack>

        <Stack spacing={2.5}>
          <FormTextField
            label="Search criteria"
            placeholder="Search by digits or phrases"
            error={!!errors.contains}
            helperText={errors.contains?.message?.toString()}
            tooltipText="Search by digits, area code, prefix, phrases, or characters you want in your phone number."
            {...register('contains', {
              required: 'Search criteria is required',
              minLength: 2,
              maxLength: 10,
            })}
            control={control}
          />

          {!!formError && <FormHelperText error>{formError.message}</FormHelperText>}
        </Stack>
      </Stack>

      {isPhoneNumberSelectModalOpen && selectedPhoneNumberItem && (
        <PropertyLocationPhoneNumberSelectModal
          propertyLocationId={Number(id)}
          phoneNumberItem={selectedPhoneNumberItem}
          onPhoneNumberSelected={onPhoneNumberSelected}
          onClose={() => setIsPhoneNumberSelectModalOpen(false)}
        />
      )}
    </SplitMuiPage>
  );
};

export default PropertyLocationPhoneCreatePage;
