import { TOnboardingProgress } from '@/types/onboarding';
import { AccountType } from '@/types/user';

import { HOPRoutes, OnboardingRoutes, PMPRoutes, Routes } from '@/config/routes';


type LocationDescriptor = string;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type RouteState = any;

export const getCurrentOnboardingStep = (
  onboardingProgress: TOnboardingProgress
): [LocationDescriptor, RouteState?] => {
  if (!onboardingProgress.hasAccountType) {
    return [
      OnboardingRoutes.accountTypeSelect,
      { autoSubmit: true, accountType: AccountType.PROPERTY_MANAGER },
    ];
  }

  if (onboardingProgress.accountType === AccountType.HOME_OWNER) {
    return [HOPRoutes.dashboard];
  }

  if (!onboardingProgress.hasPropertyLocation) {
    return [OnboardingRoutes.propertyLocationCreate];
  }

  // Redirect to dashboard after setting up Property Location
  return [PMPRoutes.dashboard];

  // if (!onboardingProgress.hasBuilding && !!onboardingProgress.firstPropertyLocationId) {
  //   return getResolvedRoutePath(PMPRoutes.propertyLocationViewBuildingList, {
  //     id: onboardingProgress.firstPropertyLocationId.toString(),
  //   });
  // }

  // if (!onboardingProgress.hasUnit && !!onboardingProgress.firstBuildingId) {
  //   return getResolvedRoutePath(PMPRoutes.buildingViewUnitList, {
  //     id: onboardingProgress.firstBuildingId.toString(),
  //   });
  // }

  // if (!onboardingProgress.hasTenant) {
  //   return OnboardingRoutes.tenantUpload;
  // }

  // return PMPRoutes.dashboard;
};

export const getNextOnboardingStep = (
  currentStep: Routes,
  onboardingProgress: TOnboardingProgress
): [LocationDescriptor, RouteState?] => {
  if (!onboardingProgress.accountType)
    return [
      OnboardingRoutes.accountTypeSelect,
      { autoSubmit: true, accountType: AccountType.PROPERTY_MANAGER },
    ];

  if (onboardingProgress.accountType === AccountType.HOME_OWNER) {
    return [HOPRoutes.dashboard];
  }

  if (currentStep === OnboardingRoutes.accountTypeSelect) {
    return [OnboardingRoutes.propertyLocationCreate];
  }

  if (currentStep === OnboardingRoutes.propertyLocationCreate) {
    return [PMPRoutes.onboardingChecklist];
  }

  // Redirect to dashboard after setting up Property Location
  return [PMPRoutes.dashboard];

  // if (!onboardingProgress.hasBuilding && !!onboardingProgress.firstPropertyLocationId) {
  //   return getResolvedRoutePath(PMPRoutes.propertyLocationViewBuildingList, {
  //     id: onboardingProgress.firstPropertyLocationId.toString(),
  //   });
  // }

  // if (!onboardingProgress.hasUnit && !!onboardingProgress.firstBuildingId) {
  //   return getResolvedRoutePath(PMPRoutes.buildingViewUnitList, {
  //     id: onboardingProgress.firstBuildingId.toString(),
  //   });
  // }

  // if (!onboardingProgress.hasTenant) {
  //   return OnboardingRoutes.tenantUpload;
  // }

  // return PMPRoutes.dashboard;
};
