import { IconButton } from '@mui/material';
import React, { useState } from 'react';

import { TPropertyLocation } from '@/types/propertyLocation';

import { DotsVerticalIcon } from '@/assets/icons/DotsVerticalIcon';

import LocationActionsMenu from './LocationActionsMenu';
import PropertyLocationUpdateModal from './PropertyLocationUpdateModal';

interface Props {
  location: TPropertyLocation;
  onLocationUpdated: (location: TPropertyLocation) => void;
}

const LocationActions: React.FC<Props> = React.memo(({ location, onLocationUpdated }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);

  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleEditClick = () => {
    setIsUpdateModalOpen(true);
    handleMenuClose();
  };

  return (
    <>
      <IconButton
        aria-label="More options"
        size="small"
        color="secondary"
        onClick={handleMenuClick}
      >
        <DotsVerticalIcon />
      </IconButton>
      <LocationActionsMenu
        anchorEl={anchorEl}
        selectedLocation={anchorEl ? location : null}
        onClose={handleMenuClose}
        onEditClick={handleEditClick}
        locationId={location.id}
      />
      {isUpdateModalOpen && (
        <PropertyLocationUpdateModal
          onClose={() => setIsUpdateModalOpen(false)}
          propertyLocation={location}
          onPropertyLocationUpdated={updatedLocation => {
            onLocationUpdated(updatedLocation);
            setIsUpdateModalOpen(false);
          }}
        />
      )}
    </>
  );
});

LocationActions.displayName = 'LocationActions';

export default LocationActions;
