import { MenuItem } from '@mui/material';
import Select from '@mui/material/Select/Select';
import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

import FormField, { FormFieldProps, getControllerProps, getFormFieldProps } from './FormField';

interface Option {
  label: React.ReactNode;
  value: string | number;
}

interface Props extends FormFieldProps {
  options: Option[];
  placeholder?: string;
}

const FormSelect = forwardRef<HTMLDivElement, Props>(
  ({ color = 'secondary', options, placeholder = 'Select value', size, ...props }, ref) => (
    <FormField {...getFormFieldProps(props)} ref={ref}>
      <Controller
        {...getControllerProps(props)}
        render={({ field }) => (
          <Select {...field} color={color} size={size}>
            <MenuItem value={undefined} disabled>
              <em>{placeholder}</em>
            </MenuItem>

            {options.map(option => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </FormField>
  )
);

export default FormSelect;
