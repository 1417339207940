import { Box, Card, Skeleton, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import ContextualMenu from '../ContextualMenu';

interface Substat {
  label: string;
  value: number;
  type: 'error' | 'warning' | 'success';
  location?: string;
  valuePrimary?: {
    type: 'error' | 'warning' | 'success';
    value: number;
  };
  valueSecondary?: {
    type: 'error' | 'warning' | 'success';
    value: number;
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  state?: any;
}

interface Props {
  stats: Substat[];
  isLoading?: boolean;
  Icon: React.ReactNode;
}

const DashboardStatsCard: React.FC<Props> = ({ stats, isLoading = false, Icon }) => {
  const history = useHistory();
  const mainStat = useMemo(() => stats[0], [stats]);

  if (isLoading) {
    return (
      <Card
        sx={{
          bgcolor: 'grey.50',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ px: 2.5, py: 1.5 }}
          gap={0.5}
        >
          <Skeleton variant="rounded" width={120} height={20} />
        </Stack>
        <Card
          sx={{
            bgcolor: 'white',
            display: 'flex',
            flexDirection: 'column',
            flex: '1 1 100%',
            px: 2.5,
            py: 2,
            borderLeft: 'none',
            borderRight: 'none',
            borderBottom: 'none',
          }}
        >
          <Stack gap={1} alignItems="center" direction="row">
            <Skeleton variant="circular" width={36} height={36} />

            <Skeleton variant="rounded" width={60} height={28} />
          </Stack>
        </Card>
      </Card>
    );
  }

  return (
    <Card
      sx={{
        bgcolor: 'grey.50',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 2.5, py: 1.5 }}
        gap={0.5}
      >
        <Stack direction="row" alignItems="center" gap={0.5}>
          <Typography variant="body4" color="text.primary">
            {mainStat?.label}
          </Typography>

          {mainStat?.value !== undefined && (
            <>
              {mainStat?.valuePrimary?.value !== undefined && (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    bgcolor: `${mainStat?.valuePrimary?.type}.dark`,
                    borderRadius: '50%',
                    width: 20,
                    height: 20,
                  }}
                >
                  <Typography variant="badge" color="white">
                    {mainStat?.valuePrimary?.value || mainStat?.value}
                  </Typography>
                </Stack>
              )}

              {mainStat?.value !== undefined && (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    bgcolor: `${mainStat?.type}.dark`,
                    borderRadius: '50%',
                    width: 20,
                    height: 20,
                  }}
                >
                  <Typography variant="badge" color="white">
                    {mainStat?.value}
                  </Typography>
                </Stack>
              )}

              {mainStat?.valueSecondary?.value !== undefined && (
                <Stack
                  alignItems="center"
                  justifyContent="center"
                  sx={{
                    bgcolor: `${mainStat?.valueSecondary?.type}.dark`,
                    borderRadius: '50%',
                    width: 20,
                    height: 20,
                  }}
                >
                  <Typography variant="badge" color="white">
                    {mainStat?.valueSecondary?.value}
                  </Typography>
                </Stack>
              )}
            </>
          )}
        </Stack>

        {stats?.length > 1 && (
          <ContextualMenu
            sx={{
              m: -1,
              mr: -1.5,
            }}
            options={stats.slice(1).map(stat => ({
              label: (
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  gap={1}
                  sx={{
                    width: '16rem',
                  }}
                >
                  <Typography variant="body4">
                    {stat.value} {stat.label}
                  </Typography>

                  <Box
                    sx={{
                      bgcolor: `${stat.type}.dark`,
                      width: 6,
                      height: 6,
                      borderRadius: '50%',
                    }}
                  />
                </Stack>
              ),
              onClick: () => stat.location && history.push(stat.location, stat.state),
            }))}
          />
        )}
      </Stack>

      <Card
        sx={{
          bgcolor: 'white',
          display: 'flex',
          flexDirection: 'column',
          flex: '1 1 100%',
          px: 2.5,
          py: 2,
          borderLeft: 'none',
          borderRight: 'none',
          borderBottom: 'none',
        }}
      >
        <Stack gap={1} alignItems="center" direction="row">
          <Stack
            alignItems="center"
            justifyContent="center"
            sx={{
              width: 36,
              height: 36,
              border: '1px solid',
              borderColor: 'grey.200',
              borderRadius: '50%',
            }}
          >
            {Icon}
          </Stack>

          <Typography variant="mediumNumber" color="text.primary" sx={{ mb: -0.5 }}>
            {mainStat?.value}
          </Typography>
        </Stack>
      </Card>
    </Card>
  );
};

export default DashboardStatsCard;
