import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React from 'react';

import { environment } from '@env';

import { TQuestionAnswers } from '@/types/question-answers/types';

import { useAuth } from '@/context/AuthProvider';

type Props = {
  questionAnswers: TQuestionAnswers;
  onClose: () => void;
  onQuestionAnswersDeleted: (questionAnswers: TQuestionAnswers) => void;
};

const QuestionAnswersDeleteModal = ({
  questionAnswers,
  onClose,
  onQuestionAnswersDeleted,
}: Props) => {
  const { session } = useAuth();

  const handleDelete = async () => {
    try {
      await axios.delete(`${environment.api}/question/${questionAnswers.id}`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      onQuestionAnswersDeleted(questionAnswers);
      onClose();
    } catch {
      enqueueSnackbar('Failed to delete Q&A item.', { variant: 'error' });
    }
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Delete Q&A Item</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Are you sure you want to delete this Q&A item? This action cannot be undone.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleDelete} color="error" variant="contained">
          Delete
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default QuestionAnswersDeleteModal;
