import { Box, Stack } from '@mui/material';

import Navigation from './navigation/Navigation';
import NavPropertyLocationSelect from './NavPropertyLocationSelect';
import NavUser from './NavUser';

const DrawerContent: React.FC = () => (
  <>
    <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
      <Navigation />
    </Box>

    <Stack spacing={2} p={2} mt={2}>
      <NavPropertyLocationSelect />

      <NavUser />
    </Stack>
  </>
);

export default DrawerContent;
