import { matchPath } from 'react-router-dom';

import { ExtractParams } from '@/types/router';

import { Routes } from '@/config/routes';

export const getResolvedRoutePath = <T extends Routes>(route: T, params: ExtractParams<T>) => {
  let resolvedRoute: string = route;

  Object.entries(params).forEach(([key, value]) => {
    resolvedRoute = resolvedRoute.replace(`/:${key}`, `/${value}`);
  });

  return resolvedRoute;
};

export const isPathMathingRoute = (path: string, route: Routes) =>
  matchPath(path, {
    path: route,
    exact: true,
    strict: false,
  });

export const isPathPartOfRoutes = (path: string, routes: Routes[]) =>
  routes.some(route => isPathMathingRoute(path, route));
