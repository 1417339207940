import { FormControlLabel, Radio, RadioGroup } from '@mui/material';
import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

import FormField, { FormFieldProps, getControllerProps, getFormFieldProps } from './FormField';

interface Option {
  label: React.ReactNode;
  value: string | number;
}

interface Props extends FormFieldProps {
  options: Option[];
}

const FormRadioGroup = forwardRef<HTMLDivElement, Props>(
  ({ color = 'secondary', options, size, ...props }, ref) => (
    <FormField {...getFormFieldProps(props)} ref={ref}>
      <Controller
        {...getControllerProps(props)}
        render={({ field }) => (
          <RadioGroup {...field}>
            {options.map(option => (
              <FormControlLabel
                key={option.value}
                value={option.value}
                label={option.label}
                control={<Radio size={size} />}
              />
            ))}
          </RadioGroup>
        )}
      />
    </FormField>
  )
);

export default FormRadioGroup;
