import { useCallback } from 'react';

import {
  anonymousUserRoutes,
  associaAuthenticatedRoutes,
  associaPublicRoutes,
  authenticatedUserRoutes,
  authenticatedPropertyManagerRoutes,
  publicRoutes,
  Routes,
} from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { isAssociaSubdomain } from '@/utils/communities';
import { isPathPartOfRoutes } from '@/utils/router';

export const useGetIsRouteAccessible = () => {
  const { user, session, shouldHaveAuthToken, isPropertyManager } = useAuth();

  return useCallback(
    (path: Routes) => {
      const isNotLoggedIn = !session && !shouldHaveAuthToken;

      if (isAssociaSubdomain())
        return isNotLoggedIn
          ? isPathPartOfRoutes(path, associaPublicRoutes)
          : isPathPartOfRoutes(path, associaAuthenticatedRoutes);

      if (isPathPartOfRoutes(path, publicRoutes)) return true;

      if (user && user.is_anonymous) return isPathPartOfRoutes(path, anonymousUserRoutes);

      if (user && isPathPartOfRoutes(path, authenticatedUserRoutes)) return true;

      if (user && isPropertyManager)
        return isPathPartOfRoutes(path, authenticatedPropertyManagerRoutes);

      return false;
    },
    [session, shouldHaveAuthToken, user, isPropertyManager]
  );
};
