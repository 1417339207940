import { RefetchOptions, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { environment } from '@env';

import { TOnboardingProgress, TPropertyManagerOnboardingProgress } from '@/types/onboarding';
import { AccountType } from '@/types/user';

import { PMPRoutes, publicRoutes } from '@/config/routes';
import { isAssociaSubdomain } from '@/utils/communities';
import { getCurrentOnboardingStep } from '@/utils/onboarding';
import { isPathPartOfRoutes } from '@/utils/router';

import LoadingState from '@/components/LoadingState';

import { useAuth } from './AuthProvider';
import { usePropertyManager } from './PropertyManagerProvider';

type OnboardingProgressContextType = {
  isLoading: boolean;
  onboardingProgress: TOnboardingProgress;
  isOnboardingComplete?: boolean;
  refetch: (options?: RefetchOptions) => void;
};

const OnboardingProgressContext = createContext<OnboardingProgressContextType | undefined>(
  undefined
);

const OnboardingProgressProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { user, session } = useAuth();
  const { selectedPropertyLocation } = usePropertyManager();
  const hasInitiallyRedirected = useRef(false);

  const location = useLocation();
  const history = useHistory();

  const hasAccountType = useMemo(() => !!user?.user_metadata.account_type, [user]);

  const isPropertyManager = useMemo(
    () => user?.user_metadata.account_type === AccountType.PROPERTY_MANAGER,
    [user]
  );

  const isHomeOwner = useMemo(
    () => user?.user_metadata.account_type === AccountType.HOME_OWNER,
    [user]
  );

  const fetchOnboardingProgressQuery = useQuery({
    enabled: !!session?.access_token,
    queryKey: [
      'GET',
      'property-manager',
      'onboarding',
      'progress',
      session?.access_token,
      user?.user_metadata.account_type,
      selectedPropertyLocation?.id,
      selectedPropertyLocation?.phone,
    ],
    queryFn: async (): Promise<TPropertyManagerOnboardingProgress> => {
      if (
        user?.user_metadata.account_type !== AccountType.PROPERTY_MANAGER ||
        !selectedPropertyLocation?.id
      ) {
        return {
          hasPropertyLocation: false,
          hasPhoneNumber: false,
          hasBuilding: false,
          hasUnit: false,
          hasTenant: false,
          firstPropertyLocationId: null,
          firstBuildingId: null,
          firstUnitId: null,
          firstTenantId: null,
        };
      }

      const response = await axios.get<TPropertyManagerOnboardingProgress>(
        `${environment.api}/property-manager/onboarding/${selectedPropertyLocation?.id}/progress`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      return response.data;
    },
  });

  const onboardingProgress: TOnboardingProgress = useMemo(() => {
    if (hasAccountType && isHomeOwner) {
      return {
        hasAccountType: true,
        accountType: AccountType.HOME_OWNER,
      };
    }

    if (hasAccountType && isPropertyManager) {
      return {
        hasAccountType: true,
        accountType: AccountType.PROPERTY_MANAGER,
        ...fetchOnboardingProgressQuery.data,
        firstPropertyLocationId:
          selectedPropertyLocation?.id ??
          fetchOnboardingProgressQuery.data?.firstPropertyLocationId,
        hasPropertyLocation:
          !!selectedPropertyLocation?.id || fetchOnboardingProgressQuery.data?.hasPropertyLocation,
      };
    }

    return {
      hasAccountType: false,
    };
  }, [
    hasAccountType,
    isHomeOwner,
    isPropertyManager,
    fetchOnboardingProgressQuery.data,
    selectedPropertyLocation,
  ]);

  const isOnboardingComplete = useMemo(
    () =>
      (hasAccountType && isHomeOwner) ||
      (hasAccountType &&
        isPropertyManager &&
        (!!selectedPropertyLocation?.id ||
          fetchOnboardingProgressQuery.data?.hasPropertyLocation) &&
        fetchOnboardingProgressQuery.data?.hasPhoneNumber &&
        fetchOnboardingProgressQuery.data?.hasBuilding &&
        fetchOnboardingProgressQuery.data?.hasUnit &&
        fetchOnboardingProgressQuery.data?.hasTenant),
    [
      fetchOnboardingProgressQuery.data,
      hasAccountType,
      isHomeOwner,
      isPropertyManager,
      selectedPropertyLocation,
    ]
  );

  const { isLoading } = fetchOnboardingProgressQuery;

  useEffect(() => {
    if (isAssociaSubdomain()) return;
    if (isLoading) return;
    if (isPathPartOfRoutes(location.pathname, publicRoutes)) return;
    if (isPathPartOfRoutes(location.pathname, [PMPRoutes.onboardingChecklist])) return;

    // If user has selected a location and onboarding is incomplete, redirect to checklist
    if (selectedPropertyLocation?.id && !isOnboardingComplete) {
      history.replace(PMPRoutes.onboardingChecklist);
      return;
    }

    if (!onboardingProgress.hasAccountType) {
      hasInitiallyRedirected.current = true;
      return history.replace(...getCurrentOnboardingStep(onboardingProgress));
    }

    if (
      (onboardingProgress.accountType === AccountType.PROPERTY_MANAGER &&
        onboardingProgress.hasTenant &&
        !onboardingProgress.hasPropertyLocation) ||
      !onboardingProgress.hasPhoneNumber
    ) {
      hasInitiallyRedirected.current = true;
      return history.replace(...getCurrentOnboardingStep(onboardingProgress));
    }
  }, [
    isLoading,
    history,
    onboardingProgress,
    location,
    selectedPropertyLocation?.id,
    isOnboardingComplete,
  ]);

  const value = useMemo(
    () => ({
      isLoading,
      onboardingProgress,
      isOnboardingComplete,
      refetch: fetchOnboardingProgressQuery.refetch,
    }),
    [isLoading, isOnboardingComplete, onboardingProgress, fetchOnboardingProgressQuery.refetch]
  );

  return (
    <OnboardingProgressContext.Provider value={value}>
      {isLoading ? <LoadingState /> : children}
    </OnboardingProgressContext.Provider>
  );
};

export const useOnboardingProgress = () => {
  const context = useContext(OnboardingProgressContext);

  if (context === undefined) {
    throw new Error('useOnboardingProgress must be used within an OnboardingProgressProvider');
  }

  return context;
};

export default OnboardingProgressProvider;
