import { FormHelperText, Stack, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import {
  PropertyLocationDeclaredUnitsCount,
  PropertyLocationType,
  TPropertyLocation,
} from '@/types/propertyLocation';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { OnboardingRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { environment } from '@/environments/environment';
import { getNextOnboardingStep } from '@/utils/onboarding';

import FormAddressAutocomplete from '@/components/form/FormAddressAutocomplete';
import FormSelect from '@/components/form/FormSelect';
import FormTextField from '@/components/form/FormTextField';
import MapboxAddressMap from '@/components/mapbox/MapboxAddressMap';

import SplitMuiPage from '../common/SplitMuiPage';

interface FormData {
  name: string;
  address_text: string;
  type: string;
  declared_units_count?: PropertyLocationDeclaredUnitsCount;
}

const OnboardingLocationPage: React.FC = () => {
  const { session } = useAuth();
  const { onboardingProgress } = useOnboardingProgress();

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();

  const history = useHistory();

  const [formError, setFormError] = useState<Error | null>(null);

  const addressText = watch('address_text');
  const propertyLocationType = watch('type');

  const createPropertyLocationMutation = useMutation({
    mutationFn: async (data: FormData) => {
      const response = await axios.post<TPropertyLocation>(
        `${environment.api}/property-location`,
        {
          ...data,
          declared_units_count:
            data.type === PropertyLocationType.MULTI_UNIT ? data.declared_units_count : undefined,
        },
        { headers: { Authorization: `Bearer ${session?.access_token}` } }
      );

      return response.data;
    },
    onError: (error: AxiosError) => {
      setFormError((error?.response?.data ?? error) as Error);
    },
    onSuccess: data => {
      history.replace(
        ...getNextOnboardingStep(OnboardingRoutes.propertyLocationCreate, {
          ...onboardingProgress,
          firstPropertyLocationId: data.id,
          hasPropertyLocation: true,
        })
      );
    },
  });

  const onSubmit = async (data: FormData) => {
    try {
      await createPropertyLocationMutation.mutate(data);
    } catch (error) {
      setFormError(error as Error);
    }
  };

  return (
    <SplitMuiPage sideContent={<MapboxAddressMap addressText={addressText} />}>
      <Stack component="form" onSubmit={handleSubmit(onSubmit)} spacing={3} width="100%">
        <Stack spacing={1.5} mb={1}>
          <Typography variant="h3">Create a property</Typography>

          <Typography variant="body2">
            Enter your building details and upload tenant information.
          </Typography>
        </Stack>

        <Stack spacing={2.5}>
          <FormTextField
            label="Property Name"
            placeholder="Enter property name"
            error={!!errors.name}
            helperText={errors.name?.message?.toString()}
            {...register('name', {
              required: 'Property Name is required',
            })}
            control={control}
          />

          <FormAddressAutocomplete
            label="Property Mailing Address"
            placeholder="Enter property mailing address"
            error={!!errors.address_text}
            helperText={errors.address_text?.message?.toString()}
            {...register('address_text', {
              required: 'Property Mailing Address is required',
            })}
            control={control}
          />

          <FormSelect
            label="Property Type"
            placeholder="Select property type"
            error={!!errors.type}
            helperText={errors.type?.message?.toString()}
            {...register('type', {
              required: 'Property Type is required',
            })}
            control={control}
            options={[
              {
                label: 'Single Family Home',
                value: PropertyLocationType.SINGLE_FAMILY_HOME,
              },
              {
                label: 'Multi-Unit',
                value: PropertyLocationType.MULTI_UNIT,
              },
            ]}
          />

          {propertyLocationType === PropertyLocationType.MULTI_UNIT && (
            <FormSelect
              label="Units Count"
              placeholder="Select units count"
              error={!!errors.declared_units_count}
              helperText={errors.declared_units_count?.message?.toString()}
              {...register('declared_units_count', {
                required: 'Units Count is required',
              })}
              control={control}
              options={[
                {
                  label: 'From 1 to 5',
                  value: PropertyLocationDeclaredUnitsCount.FROM_1_TO_5,
                },
                {
                  label: 'From 6 to 25',
                  value: PropertyLocationDeclaredUnitsCount.FROM_6_TO_25,
                },
                {
                  label: 'From 26 to 100',
                  value: PropertyLocationDeclaredUnitsCount.FROM_26_TO_100,
                },
                {
                  label: 'From 101 to 500',
                  value: PropertyLocationDeclaredUnitsCount.FROM_101_TO_500,
                },
                {
                  label: 'Above 500',
                  value: PropertyLocationDeclaredUnitsCount.ABOVE_500,
                },
              ]}
            />
          )}

          {!!formError && <FormHelperText error>{formError.message}</FormHelperText>}
        </Stack>

        <Stack spacing={1} mb={1}>
          <LoadingButton loading={isSubmitting} fullWidth variant="contained" type="submit">
            Next
          </LoadingButton>
        </Stack>
      </Stack>
    </SplitMuiPage>
  );
};

export default OnboardingLocationPage;
