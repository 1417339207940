import {
  Box,
  FormControl,
  FormHelperText,
  FormLabel,
  Stack,
  SxProps,
  Tooltip,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import { forwardRef } from 'react';
import { Control } from 'react-hook-form';

import { HelpCircleIcon } from '@/assets/icons/HelpCircleIcon';

interface Props {
  children: React.ReactNode;
  error?: boolean;
  fullWidth?: boolean;
  helperText?: string;
  label?: string;
  name?: string;
  sx?: SxProps<Theme>;
  tooltipText?: string;
}

export interface FormFieldProps extends Omit<Props, 'children' | 'name'> {
  color?: 'primary' | 'secondary';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue?: any;
  name: string;
  size?: 'small' | 'medium';
}

export const getFormFieldProps = ({
  error,
  helperText,
  fullWidth = true,
  label,
  name,
  tooltipText,
  sx,
}: FormFieldProps) => ({
  error,
  helperText,
  fullWidth,
  label,
  name,
  tooltipText,
  sx,
});

export const getControllerProps = ({ control, defaultValue, name }: FormFieldProps) => ({
  control,
  defaultValue,
  name,
});

const FormField = forwardRef<HTMLDivElement, Props>(
  ({ children, helperText, name, label, error, fullWidth = true, tooltipText, sx }, ref) => (
    <FormControl fullWidth={fullWidth} sx={sx} ref={ref}>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        {!!label && <FormLabel htmlFor={name}>{label}</FormLabel>}

        {!!tooltipText && (
          <Tooltip
            placement="top-start"
            arrow
            title={
              <Stack>
                {!!label && <Typography variant="h5">{label}</Typography>}

                <Typography variant="body3">{tooltipText}</Typography>
              </Stack>
            }
            sx={{ ml: 'auto', cursor: 'pointer' }}
          >
            <Box sx={{ cursor: 'pointer' }}>
              <HelpCircleIcon color="info" fontSize="small" />
            </Box>
          </Tooltip>
        )}
      </Stack>

      {children}

      {!!helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
    </FormControl>
  )
);

export default FormField;
