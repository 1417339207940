import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  TextField,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import { environment } from '@env';

import { TTenant } from '@/types/tenant';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { MinusIcon } from '@/assets/icons/MinusIcon';
import { PlusIcon } from '@/assets/icons/PlusIcon';
import { useAuth } from '@/context/AuthProvider';

interface Props {
  unitId: string;
  onClose: () => void;
  onTenantCreated: (unit: TTenant) => void;
}

interface Contact {
  email: string;
  phone: string;
}

const TenantCreateModal: React.FC<Props> = ({ unitId, onClose, onTenantCreated }) => {
  const [fullName, setFullName] = useState('');
  const [contacts, setContacts] = useState<Contact[]>([{ email: '', phone: '' }]);

  const { session } = useAuth();

  const addContact = () => {
    setContacts(prevContacts => [...prevContacts, { email: '', phone: '' }]);
  };

  const removeContact = (index: number) => {
    setContacts(prevContacts => prevContacts.filter((_, i) => i !== index));
  };

  const updateContact = (index: number, field: 'email' | 'phone', value: string) => {
    setContacts(prevContacts =>
      prevContacts.map((c, i) => (i === index ? { ...c, [field]: value } : c))
    );
  };

  const createTenantMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post<TTenant>(
        `${environment.api}/tenant`,
        {
          full_name: fullName,
          unit_id: Number(unitId),
          contacts: contacts.filter(contact => contact.email || contact.phone),
        },
        { headers: { Authorization: `Bearer ${session?.access_token}` } }
      );

      return response.data;
    },
    onSuccess: data => {
      onTenantCreated(data);
      enqueueSnackbar(`Tenant ${data.full_name} created successfully.`, { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to create tenant.', { variant: 'error' });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Create Tenant</DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={2} mt={2}>
          <TextField
            autoFocus
            margin="dense"
            label="Full Name"
            type="text"
            fullWidth
            value={fullName}
            onChange={e => setFullName(e.target.value)}
          />

          {contacts.map((contact, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <Stack key={index} direction="column" spacing={2}>
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField
                  margin="dense"
                  label="Email"
                  type="email"
                  fullWidth
                  value={contact.email}
                  onChange={e => updateContact(index, 'email', e.target.value)}
                />

                <TextField
                  margin="dense"
                  label="Phone"
                  type="tel"
                  fullWidth
                  value={contact.phone}
                  onChange={e => updateContact(index, 'phone', e.target.value)}
                />

                {contacts.length > 1 && (
                  <IconButton size="small" onClick={() => removeContact(index)} sx={{ mt: 1 }}>
                    <MinusIcon />
                  </IconButton>
                )}
              </Stack>
            </Stack>
          ))}

          <Button
            startIcon={<PlusIcon />}
            onClick={addContact}
            variant="outlined"
            color="secondary"
          >
            Add an additional email or phone number
          </Button>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>

        <LoadingButton
          disabled={!fullName || createTenantMutation.isPending}
          variant="contained"
          startIcon={<PlusIcon />}
          loading={createTenantMutation.isPending}
          onClick={() => createTenantMutation.mutate()}
        >
          Create
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default TenantCreateModal;
