import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';

import { environment } from '@env';

import {
  TPropertyLocation,
  PropertyLocationType,
  PropertyLocationDeclaredUnitsCount,
} from '@/types/propertyLocation';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { Save01Icon } from '@/assets/icons/Save01Icon';
import { useAuth } from '@/context/AuthProvider';

import FormAddressAutocomplete from '@/components/form/FormAddressAutocomplete';
import FormSelect from '@/components/form/FormSelect';
import FormTextField from '@/components/form/FormTextField';

interface FormData {
  name: string;
  address_text: string;
  type: keyof typeof PropertyLocationType;
  declared_units_count?: PropertyLocationDeclaredUnitsCount;
}

interface Props {
  propertyLocation: TPropertyLocation;
  onClose: () => void;
  onPropertyLocationUpdated: (propertyLocation: TPropertyLocation) => void;
}

const PropertyLocationUpdateModal: React.FC<Props> = ({
  propertyLocation,
  onClose,
  onPropertyLocationUpdated,
}) => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isValid },
  } = useForm<FormData>({
    defaultValues: {
      name: propertyLocation.name,
      address_text: propertyLocation.address_text,
      type: propertyLocation.type,
      declared_units_count: propertyLocation.declared_units_count as
        | PropertyLocationDeclaredUnitsCount
        | undefined,
    },
  });

  const propertyLocationType = watch('type');
  const { session } = useAuth();

  const editPropertyLocationMutation = useMutation({
    mutationFn: async (data: FormData) => {
      const response = await axios.put<TPropertyLocation>(
        `${environment.api}/property-location/${propertyLocation.id}`,
        data,
        { headers: { Authorization: `Bearer ${session?.access_token}` } }
      );

      return response.data;
    },
    onSuccess: data => {
      onPropertyLocationUpdated(data);
      enqueueSnackbar('Property updated successfully.', { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to update property.', { variant: 'error' });
    },
  });

  const onSubmit = (data: FormData) => {
    editPropertyLocationMutation.mutate(data);
  };

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Edit Property</DialogTitle>

      <DialogContent>
        <Stack
          direction="column"
          spacing={2}
          mt={2}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <FormTextField
            label="Name"
            placeholder="Enter property name"
            error={!!errors.name}
            helperText={errors.name?.message?.toString()}
            {...register('name', {
              required: 'Name is required',
            })}
            control={control}
          />

          <FormAddressAutocomplete
            label="Property Mailing Address"
            placeholder="Enter property mailing address"
            error={!!errors.address_text}
            helperText={errors.address_text?.message?.toString()}
            {...register('address_text', {
              required: 'Property Mailing Address is required',
            })}
            control={control}
          />

          <FormSelect
            label="Property Type"
            placeholder="Select property type"
            error={!!errors.type}
            helperText={errors.type?.message?.toString()}
            {...register('type', {
              required: 'Property Type is required',
            })}
            control={control}
            options={[
              {
                label: 'Single Family Home',
                value: PropertyLocationType.SINGLE_FAMILY_HOME,
              },
              {
                label: 'Multi-Unit',
                value: PropertyLocationType.MULTI_UNIT,
              },
            ]}
          />

          {propertyLocationType === PropertyLocationType.MULTI_UNIT && (
            <FormSelect
              label="Units Count"
              placeholder="Select units count"
              error={!!errors.declared_units_count}
              helperText={errors.declared_units_count?.message?.toString()}
              {...register('declared_units_count', {
                required: 'Units Count is required',
              })}
              control={control}
              options={[
                {
                  label: 'From 1 to 5',
                  value: PropertyLocationDeclaredUnitsCount.FROM_1_TO_5,
                },
                {
                  label: 'From 6 to 25',
                  value: PropertyLocationDeclaredUnitsCount.FROM_6_TO_25,
                },
                {
                  label: 'From 26 to 100',
                  value: PropertyLocationDeclaredUnitsCount.FROM_26_TO_100,
                },
                {
                  label: 'From 101 to 500',
                  value: PropertyLocationDeclaredUnitsCount.FROM_101_TO_500,
                },
                {
                  label: 'Above 500',
                  value: PropertyLocationDeclaredUnitsCount.ABOVE_500,
                },
              ]}
            />
          )}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>

        <LoadingButton
          disabled={!isValid}
          variant="contained"
          color="primary"
          startIcon={<Save01Icon />}
          loading={editPropertyLocationMutation.isPending}
          onClick={handleSubmit(onSubmit)}
        >
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PropertyLocationUpdateModal;
