import { Draggable } from '@hello-pangea/dnd';
import {
  Select,
  MenuItem,
  Button,
  Stack,
  Typography,
  useMediaQuery,
  Card,
  Box,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { Link } from 'react-router-dom';

import { environment } from '@env';

import { TPropertyManager } from '@/types/propertyManager';
import { TTicket } from '@/types/ticket';

import { Building01Icon } from '@/assets/icons/Building01Icon';
import { PMPRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import { formatFullDateTime } from '@/utils/date';
import { getResolvedRoutePath } from '@/utils/router';

import TicketIntentChip from '../ticket/TicketIntentChip';
import TicketSourceChip from '../ticket/TicketSourceChip';

interface Props {
  ticket: TTicket;
  index: number;
  onTicketResolved?: (ticket: TTicket) => void;
}

const KanbanTicket: React.FC<Props> = ({ ticket, index, onTicketResolved }) => {
  const { session } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const fetchPropertyManagersQuery = useQuery({
    enabled: !!session?.access_token && !!ticket.unit?.building?.id,
    queryKey: ['GET', 'property-managers', ticket.unit?.building?.id, session?.access_token],
    queryFn: async () => {
      const response = await axios.get<TPropertyManager[]>(
        `${environment.api}/property-manager/location/${ticket.unit?.building?.id}`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      return response.data;
    },
  });

  const assignTicketMutation = useMutation({
    mutationFn: async (managerId: string) => {
      const response = await axios.post(
        `${environment.api}/ticket/${ticket.id}/assign`,
        { managerId },
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );
      return response.data;
    },
    onSuccess: () => {
      enqueueSnackbar('Ticket assigned successfully', { variant: 'success' });
    },
    onError: () => {
      enqueueSnackbar('Failed to assign ticket', { variant: 'error' });
    },
  });

  return (
    <Draggable draggableId={String(ticket.id)} index={index}>
      {(provided, snapshot) => (
        <Card
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{
            userSelect: 'none',
            margin: 0,
            m: 0,
            p: 2.5,
            borderLeft: 0,
            borderRight: 0,
            borderBottom: 0,
            borderRadius: 0,
            bgcolor: snapshot.isDragging ? 'grey.50' : 'white',
            position: 'relative',
            overflow: 'hidden',
            ...provided.draggableProps.style,

            '&:hover': {
              bgcolor: 'grey.50',
            },
          }}
        >
          <Stack spacing={2.5}>
            <Stack spacing={1} direction="row" alignItems="center">
              <Stack spacing={1}>
                <Typography variant="dataGridSmall">#{ticket.id}</Typography>

                <Box>
                  {!!ticket.unit && (
                    <Typography
                      variant="dataGridTitle"
                      component={Link}
                      to={getResolvedRoutePath(PMPRoutes.unitView, {
                        id: String(ticket.unit.id),
                      })}
                      sx={{
                        textDecoration: 'none',
                        mr: 1,
                        '&:hover': { color: 'text.primary', textDecoration: 'underline' },
                      }}
                    >
                      Unit {ticket.unit.name}
                    </Typography>
                  )}

                  {!!ticket.tenant && (
                    <Typography
                      variant="dataGridBody"
                      component={Link}
                      to={getResolvedRoutePath(PMPRoutes.tenantView, {
                        id: String(ticket.tenant.id),
                      })}
                      sx={{
                        textDecoration: 'none',
                        '&:hover': { color: 'text.primary', textDecoration: 'underline' },
                      }}
                    >
                      {ticket.tenant.full_name}
                    </Typography>
                  )}
                </Box>
              </Stack>

              <Stack direction="row" spacing={1} alignItems="center" ml="auto">
                <TicketSourceChip ticket={ticket} />

                <TicketIntentChip ticket={ticket} />
              </Stack>
            </Stack>

            <Typography variant="dataGridBody">{ticket.message}</Typography>

            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="dataGridSmall" mr="auto">
                {formatFullDateTime(new Date(ticket.created_at))}
              </Typography>

              {!!ticket.unit && (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Building01Icon sx={{ fontSize: 14 }} />

                  <Typography
                    variant="dataGridBody"
                    component={Link}
                    to={getResolvedRoutePath(PMPRoutes.buildingView, {
                      id: String(ticket.unit.building.id),
                    })}
                    sx={{
                      textDecoration: 'none',
                      '&:hover': { color: 'text.primary', textDecoration: 'underline' },
                    }}
                  >
                    {ticket.unit.building.name}
                  </Typography>
                </Stack>
              )}

              <Box sx={{ width: '100%', maxWidth: 180 }}>
                <Select
                  size="small"
                  value={ticket.assigned_to || ''}
                  onChange={e => assignTicketMutation.mutate(e.target.value)}
                  onClick={e => e.stopPropagation()}
                >
                  <MenuItem value="">
                    <em>Unassigned</em>
                  </MenuItem>

                  {fetchPropertyManagersQuery.data?.map(manager => (
                    <MenuItem key={manager.id} value={manager.id}>
                      {manager.full_name || manager.email}
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              {isMobile && !ticket.resolved && !!onTicketResolved && (
                <Button
                  size="small"
                  variant="contained"
                  color="success"
                  onClick={() => onTicketResolved?.(ticket)}
                  sx={{ mb: -1, mr: -1 }}
                >
                  Resolve
                </Button>
              )}
            </Stack>
          </Stack>
        </Card>
      )}
    </Draggable>
  );
};

export default KanbanTicket;
