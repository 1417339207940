import { Theme } from '@mui/material/styles';

export default function OverridesTooltip(theme: Theme) {
  return {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: theme.palette.grey[0],
          padding: '12px',
          borderRadius: '8px',
          boxShadow:
            '0px 2px 2px -1px #0A0D120A, 0px 4px 6px -2px #0A0D1208, 0px 12px 16px -4px #0A0D1214',
        },
        arrow: {
          color: theme.palette.grey[0],
        },
      },
    },
  };
}
