import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';

import { environment } from '@env';

import { TQuestionAnswers } from '@/types/question-answers/types';

import { useAuth } from '@/context/AuthProvider';

type Props = {
  questionAnswers: TQuestionAnswers;
  onClose: () => void;
  onQuestionAnswersUpdated: (questionAnswers: TQuestionAnswers) => void;
};

const QuestionAnswersUpdateModal: React.FC<Props> = ({
  questionAnswers,
  onClose,
  onQuestionAnswersUpdated,
}) => {
  const { session } = useAuth();

  const [question, setQuestion] = useState(questionAnswers?.question || '');
  const [answer, setAnswer] = useState(questionAnswers?.answer || '');

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const updatedQuestionAnswersItem = {
      question_id: questionAnswers?.id,
      question,
      answer,
    };

    try {
      const response = await axios.put<TQuestionAnswers>(
        `${environment.api}/question`,
        updatedQuestionAnswersItem,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      onQuestionAnswersUpdated(response.data);
      onClose();
    } catch {
      enqueueSnackbar('Failed to update Q&A item.', { variant: 'error' });
    }
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit}>
        <DialogTitle id="edit-question-answers-dialog">Edit Q&A Item</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Update the Q&A item. Only changed fields will be sent to the server.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="question"
            label="Question / Topic"
            type="text"
            fullWidth
            variant="outlined"
            value={question}
            onChange={e => setQuestion(e.target.value)}
            required
          />
          <TextField
            margin="dense"
            id="answer"
            label="Answer / Guidance"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            value={answer}
            onChange={e => setAnswer(e.target.value)}
            required
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <Button type="submit" variant="contained">
            Update
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default QuestionAnswersUpdateModal;
