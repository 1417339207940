export const environment = {
  sentryDsn:
    'https://f00fe29f2c0d752d703b60ef88c3dcdb@o4507788926976000.ingest.us.sentry.io/4507788938575872', // same for dev
  // api: 'https://terrai-431617.web.app/api',
  // api: 'http://localhost:3000/api',
  api: '/api',
  direct: 'https://terrai-api-staging-356805368329.us-east4.run.app/api',
  production: false,
  stage: 'staging',
  streamChatApiKey: 'ma3ad8ybjzpk',
  supabaseUrl: 'https://ezlmocrvrydhrkavvccl.supabase.co',
  supabaseAnonKey:
    'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImV6bG1vY3J2cnlkaHJrYXZ2Y2NsIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjQ2Njk5NzMsImV4cCI6MjA0MDI0NTk3M30.MvOowakUgZ7dwF-bdWS2Ipa09WATPzAJ2Lu6EDuykoI',
  posthogUrl: 'https://us.i.posthog.com',
  posthogKey: 'phc_OgDvEHvm8UQqnlBCjagAvOtjMMYW6bLYpB34bZlcf71', // this is a non-pord key for all other envs
  mapboxApiKey:
    'pk.eyJ1IjoibmlrZWVwIiwiYSI6ImNsenJhd241MzF5aWUyaXB4eXc5dmRmYWoifQ.cSKHS-JGEVky6zK-dsMMOQ', // same for all envs
  turnstileSiteKey: '0x4AAAAAAA5_Rhq0LpxfsRAb',
};
