import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { environment } from '@env';

import { PropertyLocationType, TPropertyLocation } from '@/types/propertyLocation';

import {
  getSelectedPropertyLocationIdFromLocalStorage,
  setSelectedPropertyLocationIdInLocalStorage,
} from '@/localStorage/propertyLocation';

import LoadingState from '@/components/LoadingState';

import { useAuth } from './AuthProvider';

type PropertyManagerContextType = {
  selectedPropertyLocation: TPropertyLocation | null;
  setSelectedPropertyLocationId: (locationId: number | null) => void;
  propertyLocations: TPropertyLocation[];
  isLoading: boolean;
  isSingleFamilyAccount: boolean;
};

const PropertyManagerContext = createContext<PropertyManagerContextType | undefined>(undefined);

const PropertyManagerProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { session } = useAuth();

  const [selectedPropertyLocationId, setSelectedPropertyLocationId] = useState<number | null>(
    getSelectedPropertyLocationIdFromLocalStorage()
  );

  const handleSetSelectedPropertyLocationId = useCallback((locationId: number | null) => {
    setSelectedPropertyLocationId(locationId);
    setSelectedPropertyLocationIdInLocalStorage(locationId);
  }, []);

  const fetchPropertyLocationsQuery = useQuery({
    enabled: !!session?.access_token,
    queryKey: ['GET', 'property-location', 'all', session?.access_token],
    queryFn: async () => {
      const response = await axios.get<TPropertyLocation[]>(
        `${environment.api}/property-location/all`,
        {
          headers: {
            Authorization: `Bearer ${session?.access_token}`,
          },
        }
      );

      if (!selectedPropertyLocationId) {
        handleSetSelectedPropertyLocationId(response.data?.[0]?.id ?? null);
      }

      return response.data;
    },
  });

  const selectedPropertyLocation = useMemo(() => {
    if (!selectedPropertyLocationId) return null;

    return (
      fetchPropertyLocationsQuery.data?.find(
        location => location.id === selectedPropertyLocationId
      ) ?? null
    );
  }, [fetchPropertyLocationsQuery.data, selectedPropertyLocationId]);

  const isSingleFamilyAccount = useMemo(
    () =>
      fetchPropertyLocationsQuery.data?.length === 1 &&
      fetchPropertyLocationsQuery.data?.every(
        location => location.type === PropertyLocationType.SINGLE_FAMILY_HOME
      ),
    [fetchPropertyLocationsQuery.data]
  );

  const { isLoading } = fetchPropertyLocationsQuery;

  const value = useMemo(
    () => ({
      propertyLocations: fetchPropertyLocationsQuery.data ?? [],
      isSingleFamilyAccount,
      isLoading,
      selectedPropertyLocation,
      setSelectedPropertyLocationId: handleSetSelectedPropertyLocationId,
    }),
    [
      fetchPropertyLocationsQuery.data,
      isLoading,
      isSingleFamilyAccount,
      selectedPropertyLocation,
      handleSetSelectedPropertyLocationId,
    ]
  );

  return (
    <PropertyManagerContext.Provider value={value}>
      {isLoading ? <LoadingState /> : children}
    </PropertyManagerContext.Provider>
  );
};

export const usePropertyManager = () => {
  const context = useContext(PropertyManagerContext);

  if (context === undefined) {
    throw new Error('usePropertyManager must be used within an PropertyManagerProvider');
  }

  return context;
};

export default PropertyManagerProvider;
