import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stack,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { environment } from '@env';

import {
  PropertyLocationDeclaredUnitsCount,
  PropertyLocationType,
  TPropertyLocation,
} from '@/types/propertyLocation';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { Save01Icon } from '@/assets/icons/Save01Icon';
import { useAuth } from '@/context/AuthProvider';

import FormAddressAutocomplete from '../form/FormAddressAutocomplete';
import FormSelect from '../form/FormSelect';
import FormTextField from '../form/FormTextField';

interface Props {
  onClose: () => void;
  onPropertyLocationCreated: (propertyLocation: TPropertyLocation) => void;
}

interface FormData {
  name: string;
  address_text: string;
  type: string;
  declared_units_count?: PropertyLocationDeclaredUnitsCount;
}

const PropertyLocationCreateModal: React.FC<Props> = ({ onClose, onPropertyLocationCreated }) => {
  const [formError, setFormError] = useState<Error | null>(null);

  const { session } = useAuth();

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();

  const propertyLocationType = watch('type');

  const createPropertyLocationMutation = useMutation({
    mutationFn: async (data: FormData) => {
      const response = await axios.post<TPropertyLocation>(
        `${environment.api}/property-location`,
        {
          ...data,
          declared_units_count:
            data.type === PropertyLocationType.MULTI_UNIT ? data.declared_units_count : undefined,
        },
        { headers: { Authorization: `Bearer ${session?.access_token}` } }
      );

      return response.data;
    },
    onSuccess: data => {
      onPropertyLocationCreated(data);
      enqueueSnackbar('Location created successfully.', { variant: 'success' });
      onClose();
    },
    onError: () => {
      enqueueSnackbar('Failed to create location.', { variant: 'error' });
    },
  });

  const onSubmit = async (data: FormData) => {
    try {
      await createPropertyLocationMutation.mutate(data);
    } catch (error) {
      setFormError(error as Error);
    }
  };

  return (
    <Dialog
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      open
      onClose={onClose}
      maxWidth="xs"
      fullWidth
    >
      <DialogTitle>Create Location</DialogTitle>

      <DialogContent>
        <Stack direction="column" spacing={2} mt={2}>
          <FormTextField
            label="Property Name"
            placeholder="Enter property name"
            error={!!errors.name}
            helperText={errors.name?.message?.toString()}
            {...register('name', {
              required: 'Property Name is required',
            })}
            control={control}
          />

          <FormAddressAutocomplete
            label="Property Mailing Address"
            placeholder="Enter property mailing address"
            error={!!errors.address_text}
            helperText={errors.address_text?.message?.toString()}
            {...register('address_text', {
              required: 'Property Mailing Address is required',
            })}
            control={control}
          />

          <FormSelect
            label="Property Type"
            placeholder="Select property type"
            error={!!errors.type}
            helperText={errors.type?.message?.toString()}
            {...register('type', {
              required: 'Property Type is required',
            })}
            control={control}
            options={[
              {
                label: 'Single Family Home',
                value: PropertyLocationType.SINGLE_FAMILY_HOME,
              },
              {
                label: 'Multi-Unit',
                value: PropertyLocationType.MULTI_UNIT,
              },
            ]}
          />

          {propertyLocationType === PropertyLocationType.MULTI_UNIT && (
            <FormSelect
              label="Units Count"
              placeholder="Select units count"
              error={!!errors.declared_units_count}
              helperText={errors.declared_units_count?.message?.toString()}
              {...register('declared_units_count', {
                required: 'Units Count is required',
              })}
              control={control}
              options={[
                {
                  label: 'From 1 to 5',
                  value: PropertyLocationDeclaredUnitsCount.FROM_1_TO_5,
                },
                {
                  label: 'From 6 to 25',
                  value: PropertyLocationDeclaredUnitsCount.FROM_6_TO_25,
                },
                {
                  label: 'From 26 to 100',
                  value: PropertyLocationDeclaredUnitsCount.FROM_26_TO_100,
                },
                {
                  label: 'From 101 to 500',
                  value: PropertyLocationDeclaredUnitsCount.FROM_101_TO_500,
                },
                {
                  label: 'Above 500',
                  value: PropertyLocationDeclaredUnitsCount.ABOVE_500,
                },
              ]}
            />
          )}

          {!!formError && <FormHelperText error>{formError.message}</FormHelperText>}
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          startIcon={<Save01Icon />}
          loading={isSubmitting}
          type="submit"
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PropertyLocationCreateModal;
