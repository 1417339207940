import { matchPath } from 'react-router-dom';

import { NavItemType } from '@/@mantis/types/menu';
import { BarChartSquare02Icon } from '@/assets/icons/BarChartSquare02Icon';
import { HomeLineIcon } from '@/assets/icons/HomeLineIcon';
import { Mail02Icon } from '@/assets/icons/Mail02Icon';
import { MessageSquare01Icon } from '@/assets/icons/MessageSquare01Icon';
import { Phone02Icon } from '@/assets/icons/Phone02Icon';
import { PhoneIcon } from '@/assets/icons/PhoneIcon';
import { Ticket02Icon } from '@/assets/icons/Ticket02Icon';
import { PMPRoutes } from '@/config/routes';

export const getMenuItems = (isSingleFamilyAccount: boolean): NavItemType[] => [
  {
    id: PMPRoutes.dashboard,
    title: 'Dashboard',
    type: 'group',
    url: PMPRoutes.dashboard,
    icon: BarChartSquare02Icon,
    getIsSelected: pathname =>
      PMPRoutes.dashboard === pathname ||
      [PMPRoutes.onboardingChecklist].some(path => !!matchPath(pathname, path)),
  },
  {
    id: 'properties',
    title: 'My Properties',
    type: 'group',
    children: [
      {
        id: PMPRoutes.propertyLocationList,
        title: 'Locations',
        type: 'item',
        icon: BarChartSquare02Icon,
        url: PMPRoutes.propertyLocationList,
        getIsSelected: pathname =>
          [
            PMPRoutes.propertyLocationList,
            PMPRoutes.propertyLocationView,
            PMPRoutes.propertyLocationManagerList,
            PMPRoutes.propertyLocationViewRuleList,
          ].some(path => !!matchPath(pathname, path)),
      },
      ...(isSingleFamilyAccount
        ? []
        : [
          {
            id: PMPRoutes.buildingList,
            title: 'All Buildings',
            type: 'item',
            icon: HomeLineIcon,
            url: PMPRoutes.buildingList,
            getIsSelected: (pathname: string) =>
              [
                PMPRoutes.buildingList,
                PMPRoutes.buildingView,
                PMPRoutes.buildingViewDocumentList,
                PMPRoutes.buildingViewUnitList,
                PMPRoutes.unitView,
                PMPRoutes.unitViewDocumentList,
                PMPRoutes.unitViewKnowledgeList,
                PMPRoutes.unitViewMessageList,
                PMPRoutes.unitViewMessageView,
                PMPRoutes.unitViewTenantList,
                PMPRoutes.tenantView,
                PMPRoutes.tenantViewDocumentList,
              ].some(path => !!matchPath(pathname, path)),
          },
        ]),
    ],
  },
  {
    id: 'communications',
    title: 'Communications',
    type: 'group',
    children: [
      {
        id: PMPRoutes.conversationList,
        title: 'Conversations',
        type: 'item',
        icon: MessageSquare01Icon,
        url: PMPRoutes.conversationList,
        getIsSelected: pathname =>
          [PMPRoutes.conversationList, PMPRoutes.conversationView].some(
            path => !!matchPath(pathname, path)
          ),
      },
      {
        id: 'emails',
        title: 'Emails',
        type: 'collapse',
        icon: Mail02Icon,
        children: [
          {
            id: PMPRoutes.inboundEmailList,
            title: 'Inbound',
            type: 'item',
            url: PMPRoutes.inboundEmailList,
            getIsSelected: pathname => PMPRoutes.inboundEmailList === pathname,
          },
          {
            id: PMPRoutes.sentEmailList,
            title: 'Sent',
            type: 'item',
            url: PMPRoutes.sentEmailList,
            getIsSelected: pathname => PMPRoutes.sentEmailList === pathname,
          },
        ],
      },
      {
        id: 'sms',
        title: 'SMS',
        type: 'collapse',
        icon: Phone02Icon,
        children: [
          {
            id: PMPRoutes.inboundSmsList,
            title: 'Inbound',
            type: 'item',
            url: PMPRoutes.inboundSmsList,
            getIsSelected: pathname => PMPRoutes.inboundSmsList === pathname,
          },
          {
            id: PMPRoutes.sentSmsList,
            title: 'Sent',
            type: 'item',
            url: PMPRoutes.sentSmsList,
            getIsSelected: pathname => PMPRoutes.sentSmsList === pathname,
          },
        ],
      },
      {
        id: PMPRoutes.inboundCallList,
        title: 'Inbound Calls',
        type: 'item',
        url: PMPRoutes.inboundCallList,
        icon: PhoneIcon,
        getIsSelected: pathname => PMPRoutes.inboundCallList === pathname,
      },
    ],
  },
  {
    id: 'tickets',
    title: 'Tickets',
    type: 'group',
    url: PMPRoutes.ticketList,
    icon: Ticket02Icon,
    getIsSelected: pathname => PMPRoutes.ticketList === pathname,
  },
];
