import { PropsWithChildren } from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { HOPRoutes, OnboardingRoutes, PMPRoutes, PublicRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';
import SplitMuiPage from '@/pages/common/SplitMuiPage';
import { isAssociaSubdomain } from '@/utils/communities';

import AuthSideContent from './AuthSideContent';

export interface AuthRouteState {
  from?: Location;
}

const getRedirectRoute = (isPropertyManager: boolean, from?: Location) => {
  if (from) return from;
  if (isPropertyManager) return PMPRoutes.dashboard;
  if (isAssociaSubdomain()) return HOPRoutes.documentList;

  return PublicRoutes.chat;
};

const AuthPageWrapper: React.FC<PropsWithChildren> = ({ children }) => {
  const { user, isPropertyManager } = useAuth();
  const location = useLocation<AuthRouteState>();

  // if (user?.email && user.user_metadata.email_verified === false)
  //   return (
  //     <Redirect to={getResolvedRoutePath(OnboardingRoutes.verifyEmail, { email: user.email })} />
  //   );

  if (user && !user.user_metadata.account_type) return <Redirect to={OnboardingRoutes.index} />;

  if (user && isPropertyManager)
    return <Redirect to={getRedirectRoute(isPropertyManager, location?.state?.from)} />;

  return <SplitMuiPage sideContent={<AuthSideContent />}>{children}</SplitMuiPage>;
};

export default AuthPageWrapper;
