import { TextField, TextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { Controller } from 'react-hook-form';

import FormField, { FormFieldProps, getControllerProps, getFormFieldProps } from './FormField';

interface Props extends FormFieldProps {
  placeholder?: string;
  textFieldProps?: TextFieldProps;
}

const FormTextField = forwardRef<HTMLInputElement, Props>(
  ({ color = 'secondary', placeholder = 'Enter value', size, textFieldProps, ...props }, ref) => (
    <FormField {...getFormFieldProps(props)} ref={ref}>
      <Controller
        {...getControllerProps(props)}
        render={({ field }) => (
          <TextField
            {...textFieldProps}
            {...field}
            color={color}
            placeholder={placeholder}
            size={size}
          />
        )}
      />
    </FormField>
  )
);

export default FormTextField;
