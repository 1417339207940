import { Box, Button, Grid2, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';

import { PublicRoutes } from '@/config/routes';

import BlurBackground from './BlurBackground';

const LandingHeroSection: React.FC = () => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid2 size={12} sx={{ py: 6 }}>
      <Grid2 container spacing={2}>
        <Grid2
          size={{ xs: 12, md: 6 }}
          sx={{
            py: 6,
            pt: { md: 4 },
            display: 'flex',
            alignItems: 'flex-start',
            position: 'relative',
          }}
        >
          <BlurBackground
            isStrong
            sx={{
              left: { xs: '50%', md: 0 },
              top: 0,
              transform: 'translate(-50%, -50%)',
            }}
          />

          <Stack spacing={{ md: 6, xs: 3 }} sx={{ textAlign: { xs: 'center', md: 'initial' } }}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={0.75}
              sx={{
                width: 'auto',
                border: '1px solid #9D150033',
                borderRadius: '10px',
                py: 0.25,
                pl: 0.25,
                pr: 1.25,
                margin: { xs: '0 auto', md: '0 auto 0 0' },
              }}
            >
              <Stack
                direction="row"
                alignItems="center"
                spacing={0.5}
                sx={{
                  border: '0.5px solid #9D150080',
                  borderRadius: '6px',
                  boxShadow: '0px 1px 2px 0px #0A0D120D',
                  py: 0.125,
                  px: 0.5,
                  backgroundColor: 'white',
                }}
              >
                <Box
                  sx={{
                    width: '6px',
                    height: '6px',
                    borderRadius: '50%',
                    outline: '3px solid #9D150033',
                    backgroundColor: '#9D1500',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginLeft: '3px',
                    marginRight: '3px',
                    animation: 'pulse 500ms infinite alternate ease-in',
                    '@keyframes pulse': {
                      '0%': {
                        outlineWidth: '0px',
                      },
                      '100%': {
                        outlineWidth: '3px',
                      },
                    },
                  }}
                />

                <Typography variant="caption">New feature</Typography>
              </Stack>

              <Typography variant="caption" color="grey.700">
                Now supporting <strong>WhatsApp integration</strong>
              </Typography>
            </Stack>

            <Stack spacing={{ md: 3, xs: 2 }}>
              <Typography variant="h1">
                Revolutionizing Property
                <br />
                Management Through
                <br />
                the <strong>Power of AI</strong>
              </Typography>

              <Typography variant="body1">
                Take your property management to the next level with our cutting-edge AI-powered
                platform. Supercharge your property manager's workflow with AI.
              </Typography>
            </Stack>

            <Stack
              spacing={1.5}
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent={{ xs: 'center', md: 'initial' }}
            >
              {/* <Button
            variant="outlined"
            startIcon={<PlayCircleOutlineOutlinedIcon color="primary" fontSize="large" />}
            size="large"
            color="secondary"
          >
            Book a Demo
          </Button> */}

              <Button
                variant="contained"
                color="primary"
                size="large"
                sx={{ order: { xs: -1, sm: 0 } }}
                href={PublicRoutes.signup}
              >
                Get Started
              </Button>
            </Stack>
          </Stack>
        </Grid2>

        <Grid2 size={{ xs: 12, md: 6 }} sx={{ position: 'relative' }}>
          <BlurBackground
            sx={{
              right: { xs: '50%', sm: 0 },
              top: '100%',
              transform: 'translate(50%, -50%)',
            }}
          />

          <Box
            sx={{
              position: 'relative',
              width: { md: 1260 },
              height: { md: 'calc(100dvh - 200px)' },
              mb: { xs: 4, md: 0 },
              backgroundImage: { md: 'url(/assets/images/mockup-hero.png)', xs: 'none' },
              backgroundSize: 'contain',
              backgroundPosition: 'center left',
              backgroundRepeat: 'no-repeat',
            }}
          >
            {isDownMd && (
              <img src="/assets/images/mockup-hero.png" alt="Mockup" style={{ width: '100%' }} />
            )}

            <img
              src="/assets/images/mockup-tenant.png"
              alt="Tenant"
              style={{
                width: 307,
                maxWidth: 'min(25dvw, 25dvh)',
                position: 'absolute',
                top: '50%',
                left: 0,
                transform: 'translateY(-50%)',
              }}
            />
          </Box>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default LandingHeroSection;
