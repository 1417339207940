import { CircularProgress, Menu, MenuItem, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios, { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useState } from 'react';

import { environment } from '@env';

import { TPropertyLocation } from '@/types/propertyLocation';

import { useAuth } from '@/context/AuthProvider';

interface Props {
  anchorEl: HTMLElement | null;
  selectedLocation: TPropertyLocation | null;
  onClose: () => void;
  onEditClick: () => void;
  locationId: number;
}

const LocationActionsMenu: React.FC<Props> = ({
  anchorEl,
  selectedLocation,
  onClose,
  onEditClick,
  locationId,
}) => {
  const { session } = useAuth();
  const [downloadProgress, setDownloadProgress] = useState<number>(0);

  const downloadMutation = useMutation({
    mutationFn: async (id: number) => {
      setDownloadProgress(0);

      const response = await axios.get(`${environment.api}/document/location/${id}/download`, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
        responseType: 'blob',
        timeout: 300000, // 5 minutes timeout
        maxContentLength: Infinity,
        maxBodyLength: Infinity,
        onDownloadProgress: progressEvent => {
          if (progressEvent.total) {
            const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setDownloadProgress(progress);
          } else {
            // If total is unknown, show indeterminate progress
            setDownloadProgress(-1);
          }
        },
      });

      if (!response.data || response.data.size === 0) {
        throw new Error('Empty response received');
      }

      if (!(response.data instanceof Blob)) {
        throw new Error('Response is not a blob');
      }

      const contentType = response.headers['content-type'];
      if (!contentType || !contentType.includes('zip')) {
        throw new Error(`Invalid content type received: ${contentType}`);
      }

      const blob = new Blob([response.data], { type: contentType });

      // Get filename from content-disposition header or generate default
      const contentDisposition = response.headers['content-disposition'] || '';
      const filenameMatch = contentDisposition.match(/filename="(.+?)"/);
      const filename = filenameMatch ? filenameMatch[1] : `location_${id}_documents.zip`;

      // Create download link and trigger download
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.download = filename;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(link);

      return { success: true };
    },
    onSuccess: () => {
      setDownloadProgress(0);
      onClose();
      enqueueSnackbar('Documents downloaded successfully.', { variant: 'success' });
    },
    onError: (error: unknown) => {
      if (error instanceof AxiosError) {
        if (error.code === 'ECONNABORTED') {
          enqueueSnackbar('Download timed out. Please try again.', { variant: 'error' });
        } else if (error.response?.status === 404) {
          enqueueSnackbar('No documents found for this location.', { variant: 'warning' });
        } else if (error.response?.status === 403) {
          enqueueSnackbar('You do not have permission to download these documents.', {
            variant: 'error',
          });
        } else {
          enqueueSnackbar(`Failed to download location documents: ${error.message}`, {
            variant: 'error',
          });
        }
      } else if (error instanceof Error) {
        enqueueSnackbar(`Download failed: ${error.message}`, { variant: 'error' });
      } else {
        enqueueSnackbar('Failed to download location documents.', { variant: 'error' });
      }
      setDownloadProgress(0);
      onClose();
    },
  });

  return (
    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl) && selectedLocation?.id === locationId}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      slotProps={{
        paper: {
          elevation: 0,
          sx: {
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
          },
        },
      }}
    >
      <MenuItem onClick={onEditClick}>Edit Location</MenuItem>
      <MenuItem
        onClick={() => downloadMutation.mutate(locationId)}
        disabled={downloadMutation.isPending}
        sx={{
          display: 'flex',
          gap: 1,
          alignItems: 'center',
          minWidth: '200px',
        }}
      >
        {downloadMutation.isPending && selectedLocation?.id === locationId && (
          <>
            <CircularProgress
              size={16}
              variant={downloadProgress > 0 ? 'determinate' : 'indeterminate'}
              value={downloadProgress}
            />
            {downloadProgress > 0 && (
              <Typography variant="caption" color="text.secondary">
                {`${downloadProgress}%`}
              </Typography>
            )}
          </>
        )}
        Download Documents
      </MenuItem>
    </Menu>
  );
};

export default LocationActionsMenu;
