export enum PublicRoutes {
  home = '/',
  aboutUs = '/about-us',
  login = '/login',
  logout = '/logout',
  privacyPolicy = '/privacy-policy',
  termsOfService = '/terms-of-service',
  signup = '/signup',
  chat = '/chat',
}

export enum HOPRoutes {
  dashboard = '/dashboard',
  documentList = '/documents',
  documentView = '/documents/:id',
  propertyCreate = '/properties/add',
  propertyList = '/properties',
  propertyView = '/properties/:id',
}

export enum OnboardingRoutes {
  index = '/onboarding',
  propertyLocationCreate = '/onboarding/property-location',
  propertyLocationPhoneCreate = '/onboarding/property-location/:id/phone-number',
  tenantUpload = '/onboarding/tenants',
  accountTypeSelect = '/onboarding/account-type',
  verifyEmail = '/verify-email/:email',
}

export enum PMPRoutes {
  dashboard = '/property-manager',

  onboardingChecklist = '/property-manager/onboarding-checklist',

  ruleList = '/property-manager/rules',

  buildingList = '/property-manager/buildings',
  buildingView = '/property-manager/buildings/:id',
  buildingViewUnitList = '/property-manager/buildings/:id/units',
  buildingViewDocumentList = '/property-manager/buildings/:id/documents',

  documentView = '/property-manager/documents/:id',

  propertyLocationList = '/property-manager/property-locations',
  propertyLocationView = '/property-manager/property-locations/:id',
  propertyLocationViewBuildingList = '/property-manager/property-locations/:id/buildings',
  propertyLocationViewQuestionAnswersList = '/property-manager/property-locations/:id/question-answers',
  propertyLocationManagerList = '/property-manager/property-locations/:id/managers',
  propertyLocationViewRuleList = '/property-manager/property-locations/:id/rules',
  propertyLocationViewKnowledge = '/property-manager/property-locations/:id/knowledge',

  unitView = '/property-manager/units/:id',
  unitViewDocumentList = '/property-manager/units/:id/documents',
  unitViewKnowledgeList = '/property-manager/units/:id/knowledge-base',
  unitViewMessageList = '/property-manager/units/:id/messages',
  unitViewMessageView = '/property-manager/units/:id/messages/:messageId',
  unitViewTenantList = '/property-manager/units/:id/tenants',
  unitViewPastTenantList = '/property-manager/units/:id/past-tenants',

  tenantView = '/property-manager/tenants/:id',
  tenantViewDocumentList = '/property-manager/tenants/:id/documents',

  inboundEmailList = '/property-manager/inbound-emails',
  sentEmailList = '/property-manager/sent-emails',

  inboundSmsList = '/property-manager/inbound-sms',
  sentSmsList = '/property-manager/sent-sms',

  inboundCallList = '/property-manager/inbound-call',

  issueList = '/property-manager/issues',

  account = '/property-manager/account',

  tenantViewSMSList = '/property-manager/tenants/:id/sms',
  ticketList = '/property-manager/tickets',

  conversationList = '/property-manager/conversations',
  conversationView = '/property-manager/conversations/:id',
}

export type Routes = PublicRoutes | HOPRoutes | PMPRoutes | OnboardingRoutes;

// No chat
export const associaPublicRoutes: Routes[] = [
  PublicRoutes.home,
  PublicRoutes.aboutUs,
  PublicRoutes.login,
  PublicRoutes.logout,
  PublicRoutes.privacyPolicy,
  PublicRoutes.termsOfService,
  PublicRoutes.signup,
];

export const associaAuthenticatedRoutes = [
  ...associaPublicRoutes,
  HOPRoutes.documentList,
  HOPRoutes.documentView,
];

// With chat
// We need chat to be public, because
// we create the user within the flow.
export const publicRoutes = [...Object.values(PublicRoutes)];

export const anonymousUserRoutes = [...publicRoutes, HOPRoutes.dashboard];

export const authenticatedUserRoutes = [
  ...publicRoutes,
  ...Object.values(OnboardingRoutes),
  ...Object.values(HOPRoutes),
];

export const authenticatedPropertyManagerRoutes = [
  ...authenticatedUserRoutes,
  ...Object.values(PMPRoutes),
];
