import { Box, useTheme } from '@mui/material';
import mapboxgl, { LngLatBoundsLike } from 'mapbox-gl';
import React, { useEffect, useRef } from 'react';

import { environment } from '@env';

interface Props {
  locations: Array<{
    coordinates: [number, number] | null;
  }>;
  mapStyle?: string;
  interactive?: boolean;
  zoom?: number;
  bounds?: LngLatBoundsLike;
}

const MapboxMap: React.FC<Props> = ({
  locations,
  mapStyle = 'mapbox://styles/mapbox/streets-v12',
  interactive = false,
  zoom = 4,
  bounds = [
    [-125, 24],
    [-66, 50],
  ],
}) => {
  const initialOptions = useRef({
    mapStyle,
    interactive,
    zoom,
    bounds,
  });

  const theme = useTheme();
  const mapContainer = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const markersRef = useRef<mapboxgl.Marker[]>([]);

  useEffect(() => {
    if (!mapContainer.current) return;

    // Hide the container, so we don't get a screen flicker
    mapContainer.current.style.opacity = '0';

    mapRef.current = new mapboxgl.Map({
      container: mapContainer.current,
      accessToken: environment.mapboxApiKey,
      style: initialOptions.current.mapStyle,
      interactive: initialOptions.current.interactive,
      zoom: initialOptions.current.zoom,
      bounds: initialOptions.current.bounds,
      trackResize: true,
    });

    mapRef.current.on('load', () => {
      // Remove Mapbox logo
      const logoEl = mapContainer.current?.querySelector('.mapboxgl-ctrl-logo');
      if (logoEl) {
        logoEl.remove();
      }

      // Remove Mapbox attribution
      const attrEl = mapContainer.current?.querySelector('.mapboxgl-ctrl-attrib');
      if (attrEl) {
        attrEl.remove();
      }

      // Resize map so it fits the container
      mapRef.current?.resize();

      // And show the container after resizing
      if (mapContainer.current) {
        mapContainer.current.style.opacity = '1';
      }
    });

    return () => {
      // Clean up map
      mapRef.current?.remove();
    };
  }, []);

  useEffect(() => {
    if (!mapRef.current) return;

    // Clean up previous markers
    markersRef.current.forEach(marker => marker.remove());
    markersRef.current = [];

    // Add new markers for each location
    locations.forEach(location => {
      if (!location.coordinates || !mapRef.current) return;

      const marker = new mapboxgl.Marker({
        anchor: 'center',
        draggable: false,
        color: theme.palette.primary.main,
        pitchAlignment: 'map',
        rotationAlignment: 'map',
      })
        .setLngLat(location.coordinates)
        .addTo(mapRef.current);

      markersRef.current.push(marker);
    });

    // Fit bounds to include all markers if we have any
    if (locations.length > 0) {
      const validLocations = locations.filter(loc => loc.coordinates !== null);
      if (validLocations.length > 0) {
        const markerBounds = new mapboxgl.LngLatBounds();
        validLocations.forEach(location => {
          if (location.coordinates) {
            markerBounds.extend(location.coordinates);
          }
        });
        mapRef.current.fitBounds(markerBounds, { padding: 50 });
      }
    }

    return () => {
      // Clean up markers
      markersRef.current.forEach(marker => marker.remove());
      markersRef.current = [];
    };
  }, [locations, theme]);

  return (
    <Box
      ref={mapContainer}
      sx={{
        width: '100%',
        height: '100%',
        position: 'relative',
        overflow: 'hidden',
        transition: 'opacity 300ms ease-in',
        '& .mapboxgl-marker': {
          position: 'absolute',
          top: 0,
          left: 0,
        },
      }}
    />
  );
};

export default MapboxMap;
