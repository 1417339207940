export enum MessageThreadRole {
  ai = 'ai',
  tenant = 'tenant',
  property_manager = 'property_manager',
}

export enum MessageSessionType {
  email = 'email',
  sms = 'sms',
}

export interface TMessageSession {
  type: MessageSessionType;
  session_id: string;
  tenant_id: number;
  building_id: number;
  from: {
    name?: string;
    source: string; // email or phone number
    to: string;
  };
  subject: string;
  updated_at: string;
  created_at: string;
}

export interface Message {
  id: number;
  body: string | null;
  sender: string;
  media_url: string | null;
  created_at: Date;
  conversation_sid: string;
}

export interface TMessageThreadMessage {
  id: string;
  message: string;
  created_at: Date;
  updated_at: Date;
  role: MessageThreadRole;
  sender_name?: string;
  participant_sid?: string;
}

export interface TMessageThread {
  session: TMessageSession;
  messages: TMessageThreadMessage[];
}
