import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from '@mui/material';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';
import React from 'react';
import { useForm } from 'react-hook-form';

import { environment } from '@env';

import { TQuestionAnswers } from '@/types/question-answers/types';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { useAuth } from '@/context/AuthProvider';

type Props = {
  onClose: () => void;
  onQuestionAnswersCreated: (questionAnswers: TQuestionAnswers) => void;
};

interface FormData {
  question: string;
  answer: string;
}

const QuestionAnswersCreateModal: React.FC<Props> = ({ onClose, onQuestionAnswersCreated }) => {
  const { session } = useAuth();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();

  const onSubmit = async (data: FormData) => {
    try {
      const response = await axios.post<TQuestionAnswers>(`${environment.api}/question`, data, {
        headers: {
          Authorization: `Bearer ${session?.access_token}`,
        },
      });

      onQuestionAnswersCreated(response.data);
      onClose();
    } catch {
      enqueueSnackbar('Failed to add Q&A item.', { variant: 'error' });
    }
  };

  return (
    <Dialog open onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle id="add-question-answers-dialog">Add Q&A Item</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Enter any Q&A that you want Terr.ai to learn. This will help generate the right
            responses for your tenants.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="question"
            label="Question / Topic"
            type="text"
            fullWidth
            variant="outlined"
            error={!!errors.question}
            helperText={errors.question?.message?.toString()}
            {...register('question', {
              required: 'Question is required',
            })}
          />
          <TextField
            margin="dense"
            id="answer"
            label="Answer / Guidance"
            type="text"
            fullWidth
            variant="outlined"
            multiline
            rows={4}
            error={!!errors.answer}
            helperText={errors.answer?.message?.toString()}
            {...register('answer', {
              required: 'Answer is required',
            })}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose}>Cancel</Button>
          <LoadingButton loading={isSubmitting} type="submit" variant="contained">
            Add
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default QuestionAnswersCreateModal;
