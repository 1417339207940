import {
  Stack,
  Typography,
  Link,
  FormControlLabel,
  Checkbox,
  Button,
  FormHelperText,
} from '@mui/material';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { GoogleIcon } from '@/assets/icons/social/GoogleIcon';
import { PublicRoutes } from '@/config/routes';
import { useAuth } from '@/context/AuthProvider';

import FormCaptcha from '@/components/form/FormCaptcha';
import FormTextField from '@/components/form/FormTextField';
import TermsOfServiceModal from '@/components/legal/TermsOfServiceModal';

import AuthPageWrapper from './AuthPageWrapper';

interface FormData {
  full_name: string;
  email: string;
  password: string;
  has_accepted_terms: boolean;
  captchaToken: string;
}

const SignUpPage: React.FC = () => {
  const { signUp, signInWithOAuth } = useAuth();

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormData>();
  const [formError, setFormError] = useState<Error | null>(null);
  const [isTermsOfServiceModalOpen, setIsTermsOfServiceModalOpen] = useState(false);

  const onSignUpWithGoogle = async () => {
    setFormError(null);

    try {
      const { error } = await signInWithOAuth({
        provider: 'google',
        options: {
          redirectTo: window.location.href,
        },
      });

      if (error) throw error;
    } catch (error) {
      setFormError(error);
    }
  };

  const onSignUpWithPassword = async (data: FormData) => {
    const { email, password, full_name, has_accepted_terms, captchaToken } = data;
    setFormError(null);

    try {
      const { error } = await signUp({
        email,
        password,
        options: {
          emailRedirectTo: window.location.href,
          captchaToken,
          data: {
            full_name,
            has_accepted_terms,
          },
        },
      });

      if (error) throw error;
    } catch (error) {
      setFormError(error);
    }
  };

  return (
    <AuthPageWrapper>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSignUpWithPassword)}
        spacing={3}
        width="100%"
      >
        <Stack spacing={1.5} mb={1}>
          <Typography variant="h3">Sign up</Typography>

          <Typography variant="body2">Start your 30-day free trial.</Typography>
        </Stack>

        <Stack spacing={2.5}>
          <FormTextField
            label="Name"
            placeholder="Enter your name"
            textFieldProps={{
              autoComplete: 'full_name',
              autoFocus: true,
            }}
            error={!!errors.full_name}
            helperText={errors.full_name?.message?.toString()}
            control={control}
            {...register('full_name', {
              required: 'Name is required',
            })}
          />

          <FormTextField
            label="Email"
            placeholder="Enter your email"
            textFieldProps={{
              autoComplete: 'email',
              autoFocus: true,
              type: 'email',
            }}
            error={!!errors.email}
            helperText={errors.email?.message?.toString()}
            control={control}
            {...register('email', {
              required: 'Email is required',
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: 'Invalid email address',
              },
            })}
          />

          <FormTextField
            label="Password"
            placeholder="Create a password"
            textFieldProps={{
              autoComplete: 'password',
              type: 'password',
            }}
            error={!!errors.password}
            helperText={errors.password?.message?.toString() ?? 'Must be at least 8 characters.'}
            control={control}
            {...register('password', {
              required: 'Password is required',
              minLength: {
                value: 8,
                message: 'Password must be at least 8 characters',
              },
            })}
          />

          <Stack spacing={0.75}>
            <FormControlLabel
              {...register('has_accepted_terms', {
                required: 'You must agree to the terms and conditions',
              })}
              control={<Checkbox />}
              label={
                <Typography variant="body2">
                  I agree to the{' '}
                  <Button
                    onClick={() => setIsTermsOfServiceModalOpen(true)}
                    sx={{
                      p: 0,
                      minWidth: 0,
                      textTransform: 'none',
                      color: 'primary.main',
                      '&:hover': {
                        bgcolor: 'transparent',
                        textDecoration: 'underline',
                      },
                    }}
                  >
                    Terms and Conditions.
                  </Button>
                </Typography>
              }
            />

            {!!errors.has_accepted_terms && (
              <FormHelperText error>
                {errors.has_accepted_terms?.message?.toString()}
              </FormHelperText>
            )}
          </Stack>

          <FormCaptcha
            control={control}
            error={!!errors.captchaToken}
            helperText={errors.captchaToken?.message?.toString()}
            {...register('captchaToken', {
              required: 'Captcha Token is required',
            })}
          />

          {!!formError && <FormHelperText error>{formError.message}</FormHelperText>}
        </Stack>

        <Stack spacing={1} mb={1}>
          <LoadingButton loading={isSubmitting} fullWidth variant="contained" type="submit">
            Get Started
          </LoadingButton>

          <Button
            fullWidth
            color="secondary"
            variant="outlined"
            onClick={onSignUpWithGoogle}
            startIcon={<GoogleIcon />}
          >
            Sign up with Google
          </Button>
        </Stack>

        <Typography variant="body3" textAlign="center">
          Already have an account? <Link href={PublicRoutes.login}>Log in</Link>
        </Typography>
      </Stack>

      {isTermsOfServiceModalOpen && (
        <TermsOfServiceModal onClose={() => setIsTermsOfServiceModalOpen(false)} />
      )}
    </AuthPageWrapper>
  );
};

export default SignUpPage;
