import { Typography } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Dispatch, SetStateAction } from 'react';
import { Link, useLocation, matchPath } from 'react-router-dom';

import IconButton from '@/@mantis/components/@extended/IconButton';
import { NavActionType } from '@/@mantis/config';
import { useDrawer } from '@/@mantis/contexts/DrawerContext';
import { LinkTarget, NavItemType } from '@/@mantis/types/menu';

interface Props {
  item: NavItemType;
  level: number;
  isParents?: boolean;
  setSelectedID?: Dispatch<SetStateAction<string | undefined>>;
}

const NavItem: React.FC<Props> = ({ item, level, isParents = false, setSelectedID }) => {
  const theme = useTheme();

  const { isOpen, setIsOpen } = useDrawer();

  const downLG = useMediaQuery(theme.breakpoints.down('lg'));

  let itemTarget: LinkTarget = '_self';
  if (item.target) {
    itemTarget = '_blank';
  }

  const itemHandler = () => {
    if (downLG) setIsOpen(false);

    if (isParents && setSelectedID) {
      setSelectedID(undefined);
    }
  };

  const location = useLocation();
  const isSelected =
    item.getIsSelected?.(location.pathname) ??
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    !!matchPath(location.pathname, (item.link ?? item.url)!);

  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  const Icon = item.icon!;
  const itemIcon = item.icon ? (
    <Icon
      style={{
        fontSize: '1.5rem',
      }}
    />
  ) : (
    false
  );

  return (
    <Box sx={{ position: 'relative' }}>
      <ListItemButton
        component={Link}
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        to={item.url!}
        target={itemTarget}
        disabled={item.disabled}
        selected={isSelected}
        sx={{
          zIndex: 1201,
          pl: isOpen ? `${level * 28}px` : 1.5,
          py: !isOpen && level === 1 ? 1.25 : 1,
          ...(isOpen && {
            '&:hover': {
              bgcolor: theme.palette.grey[100],
            },
            '&.Mui-selected': {
              bgcolor: theme.palette.grey[50],
              borderRight: '2px solid',
              borderColor: 'primary.main',
              '&:hover': {
                bgcolor: theme.palette.grey[100],
              },
            },
          }),
          ...(!isOpen && {
            '&:hover': { bgcolor: 'transparent' },
            '&.Mui-selected': { '&:hover': { bgcolor: 'transparent' }, bgcolor: 'transparent' },
          }),
        }}
        onClick={() => itemHandler()}
      >
        {itemIcon && (
          <ListItemIcon
            sx={{
              width: 36,
              height: 24,
              color: isSelected ? 'primary.main' : 'grey.500',
              ...(!isOpen && {
                width: 36,
                height: 36,
                alignItems: 'center',
                justifyContent: 'center',
                borderRadius: 1.5,
              }),
            }}
          >
            {itemIcon}
          </ListItemIcon>
        )}
        {(isOpen || (!isOpen && level !== 1)) &&
          (level === 1 ? (
            <ListItemText
              primary={
                <Typography
                  component="span"
                  variant="body2"
                  color={isSelected ? 'text.primary' : 'text.secondary'}
                  sx={{
                    fontFamily: 'Nohemi',
                  }}
                >
                  {item.title}
                </Typography>
              }
            />
          ) : (
            <ListItemText
              secondary={
                <Typography
                  component="span"
                  variant="body3"
                  color={isSelected ? 'text.primary' : 'text.secondary'}
                >
                  {item.title}
                </Typography>
              }
            />
          ))}
        {(isOpen || (!isOpen && level !== 1)) && item.chip && (
          <Chip
            color={item.chip.color}
            variant={item.chip.variant}
            size={item.chip.size}
            label={item.chip.label}
            avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
          />
        )}
      </ListItemButton>

      {(isOpen || (!isOpen && level !== 1)) &&
        item?.actions &&
        item?.actions.map((action, index) => {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const ActionIcon = action.icon!;
          const callAction = action?.function;
          return (
            <IconButton
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              {...(action.type === NavActionType.FUNCTION && {
                onClick: event => {
                  event.stopPropagation();
                  callAction();
                },
              })}
              {...(action.type === NavActionType.LINK && {
                component: Link,
                to: action.url,
                target: action.target ? '_blank' : '_self',
              })}
              color="secondary"
              variant="outlined"
              sx={{
                position: 'absolute',
                top: 12,
                right: 20,
                zIndex: 1202,
                width: 20,
                height: 20,
                mr: -1,
                ml: 1,
                color: 'secondary.dark',
                borderColor: isSelected ? 'primary.light' : 'secondary.light',
                '&:hover': { borderColor: isSelected ? 'primary.main' : 'secondary.main' },
              }}
            >
              <ActionIcon style={{ fontSize: '0.625rem' }} />
            </IconButton>
          );
        })}
    </Box>
  );
};

export default NavItem;
