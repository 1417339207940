import { Typography } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import Stack from '@mui/material/Stack';
import * as React from 'react';
import flattenChildren from 'react-keyed-flatten-children';
import { Link } from 'react-router-dom';

import { SlashDividerIcon } from '@/assets/icons/SlashDividerIcon';
import { getSkeletonWidth } from '@/utils/skeleton';

type Breadcrumb = {
  label: string;
} & (
  | {
      to: string;
      onClick?: never;
    }
  | {
      to?: never;
      onClick: () => void;
    }
);

interface Props {
  title: string | undefined;
  size?: 'small' | 'medium';
  breadcrumbs?: Breadcrumb[];
  hasInnerHtml?: boolean;
  children?: React.ReactNode;
}

const DashboardHeader: React.FC<Props> = ({
  title,
  breadcrumbs,
  hasInnerHtml,
  children,
  size = 'medium',
}) => {
  // title set to undefined means the page is loading
  if (title === undefined)
    return (
      <Stack direction="row" spacing={1} justifyContent="space-between" alignItems="center">
        <Stack spacing={1}>
          {!!breadcrumbs && (
            <Stack direction="row" spacing={0.5} alignItems="center">
              {breadcrumbs.map((crumb, i, arr) => (
                <React.Fragment key={crumb.label}>
                  <Typography variant="body3">
                    <Skeleton width={getSkeletonWidth(40, 80)} />
                  </Typography>

                  {i < arr.length - 1 && (
                    <SlashDividerIcon sx={{ fontSize: 14, color: 'text.secondary' }} />
                  )}
                </React.Fragment>
              ))}
            </Stack>
          )}

          <Typography variant="h3">
            <Skeleton width={getSkeletonWidth(180, 300)} />
          </Typography>
        </Stack>

        {!!children && (
          <Stack direction="row" spacing={1} ml="auto">
            {flattenChildren(children).map(() => (
              <Skeleton width={getSkeletonWidth(80, 120)} height={40} />
            ))}
          </Stack>
        )}
      </Stack>
    );

  return (
    <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
      <Stack spacing={1}>
        {!!breadcrumbs && (
          <Stack direction="row" spacing={0.5} alignItems="center">
            {breadcrumbs.map((crumb, i, arr) =>
              crumb.label?.trim() ? (
                <React.Fragment key={crumb.label}>
                  <Typography
                    component={crumb.to ? Link : 'button'}
                    to={crumb.to}
                    onClick={crumb.onClick}
                    type={crumb.to ? undefined : 'button'}
                    variant="body3"
                    sx={{
                      textDecoration: 'none',
                      '&:hover': { color: 'text.primary', textDecoration: 'underline' },
                    }}
                  >
                    {crumb.label}
                  </Typography>

                  {i < arr.length - 1 && (
                    <SlashDividerIcon sx={{ fontSize: 14, color: 'text.secondary' }} />
                  )}
                </React.Fragment>
              ) : null
            )}
          </Stack>
        )}

        <Typography
          variant={size === 'small' ? 'h5' : 'h3'}
          sx={{
            '& em': {
              fontStyle: 'normal',
              color: 'primary.main',
            },
          }}
          {...(hasInnerHtml
            ? {
              dangerouslySetInnerHTML: { __html: title },
            }
            : {
              children: title,
            })}
        />
      </Stack>

      {!!children && (
        <Stack direction="row" spacing={1} alignItems="center" ml="auto">
          {children}
        </Stack>
      )}
    </Stack>
  );
};
export default DashboardHeader;
