import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { enqueueSnackbar } from 'notistack';

import { environment } from '@env';

import {
  TPropertyLocation,
  TPropertyLocationSearchPhoneNumberItem,
} from '@/types/propertyLocation';

import LoadingButton from '@/@mantis/components/@extended/LoadingButton';
import { Save01Icon } from '@/assets/icons/Save01Icon';
import { useAuth } from '@/context/AuthProvider';
import { getAxiosErrorMessage } from '@/utils/axios';

interface Props {
  phoneNumberItem: TPropertyLocationSearchPhoneNumberItem;
  propertyLocationId: number;
  onClose: () => void;
  onPhoneNumberSelected: (propertyLocation: TPropertyLocation) => void;
}

const PropertyLocationPhoneNumberSelectModal: React.FC<Props> = ({
  phoneNumberItem,
  propertyLocationId,
  onClose,
  onPhoneNumberSelected,
}) => {
  const { session } = useAuth();

  const createPropertyLocationMutation = useMutation({
    mutationFn: async () => {
      const response = await axios.post<TPropertyLocation>(
        `${environment.api}/property-location/${propertyLocationId}/phone-number`,
        { phoneNumber: phoneNumberItem.phoneNumber },
        { headers: { Authorization: `Bearer ${session?.access_token}` } }
      );

      return response.data;
    },
    onSuccess: data => {
      enqueueSnackbar('Phone number selected successfully.', { variant: 'success' });
      onPhoneNumberSelected(data);
      onClose();
    },
    onError: error => {
      enqueueSnackbar(`Failed to select phone number. ${getAxiosErrorMessage(error)}`, {
        variant: 'error',
      });
    },
  });

  return (
    <Dialog open onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Select Phone Number</DialogTitle>

      <DialogContent>
        <Stack spacing={1.5}>
          <Typography variant="body2">
            You are going to add a phone number to your property.
          </Typography>

          <Typography variant="body2">
            Selected phone number: <strong>{phoneNumberItem.friendlyName}</strong>.
          </Typography>

          <Typography variant="body2">
            <strong>This action is irreversible.</strong>
          </Typography>
        </Stack>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>

        <LoadingButton
          variant="contained"
          startIcon={<Save01Icon />}
          loading={createPropertyLocationMutation.isPending}
          onClick={() => createPropertyLocationMutation.mutate()}
        >
          Add
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default PropertyLocationPhoneNumberSelectModal;
