import { Select, MenuItem } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useCallback } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';

import { PMPRoutes } from '@/config/routes';
import { useOnboardingProgress } from '@/context/OnboardingProgressProvider';
import { usePropertyManager } from '@/context/PropertyManagerProvider';

interface FormData {
  propertyLocationId: number;
}

const NavPropertyLocationSelect: React.FC = () => {
  const { selectedPropertyLocation, propertyLocations, setSelectedPropertyLocationId } =
    usePropertyManager();
  const { isOnboardingComplete } = useOnboardingProgress();
  const history = useHistory();
  const queryClient = useQueryClient();

  const { control } = useForm<FormData>({
    defaultValues: {
      propertyLocationId: selectedPropertyLocation?.id,
    },
  });

  const handleLocationChange = useCallback(
    async (newLocationId: number) => {
      if (newLocationId && newLocationId !== selectedPropertyLocation?.id) {
        await setSelectedPropertyLocationId(newLocationId);
        // Invalidate all queries that depend on the selected location
        await queryClient.invalidateQueries({
          queryKey: ['GET', 'property-manager', 'onboarding', 'progress'],
          refetchType: 'all',
        });

        if (!isOnboardingComplete) {
          history.replace(PMPRoutes.onboardingChecklist);
        }
      }
    },
    [
      selectedPropertyLocation?.id,
      setSelectedPropertyLocationId,
      queryClient,
      isOnboardingComplete,
      history,
    ]
  );

  return (
    <Controller
      name="propertyLocationId"
      control={control}
      render={({ field }) => (
        <Select
          {...field}
          onChange={e => {
            field.onChange(e);
            handleLocationChange(e.target.value as number);
          }}
          displayEmpty
        >
          <MenuItem value="" disabled>
            <em>Select Property Location</em>
          </MenuItem>
          {propertyLocations.map(location => (
            <MenuItem key={location.id} value={location.id}>
              {location.name}
            </MenuItem>
          ))}
        </Select>
      )}
    />
  );
};

export default NavPropertyLocationSelect;
