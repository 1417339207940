import {
  Box,
  Button,
  Drawer,
  Grid2,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Stack,
} from '@mui/material';
import React, { useState } from 'react';
import { Link, useRouteMatch } from 'react-router-dom';

import IconButton from '@/@mantis/components/@extended/IconButton';
import { Menu01Icon } from '@/assets/icons/Menu01Icon';
import { Menu02Icon } from '@/assets/icons/Menu02Icon';
import { PublicRoutes } from '@/config/routes';

const NavSection: React.FC = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const isAboutUsPage = useRouteMatch(PublicRoutes.aboutUs);

  return (
    <Grid2 size={12}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          p: 1.5,
          border: { md: '1px solid #00000033' },
          borderRadius: '10px',
          backgroundColor: '#FFFFFF30',
          alignItems: 'center',
          lineHeight: 1,
        }}
      >
        <Link to={PublicRoutes.home}>
          <img src="/assets/images/terrai-logo.svg" alt="Terrai" style={{ height: 28 }} />
        </Link>

        <Stack direction="row" spacing={1.5} sx={{ display: { xs: 'none', md: 'flex' } }}>
          {!isAboutUsPage && (
            <Button variant="outlined" color="secondary" href={PublicRoutes.aboutUs}>
              About Us
            </Button>
          )}

          <Button variant="contained" color="primary" href={PublicRoutes.signup}>
            Get Started
          </Button>
        </Stack>

        <IconButton
          color="secondary"
          size="large"
          sx={{
            display: { xs: 'flex', md: 'none' },
            '&:hover': {
              bgcolor: 'transparent',
              color: 'primary.main',
            },
          }}
          onClick={() => setIsDrawerOpen(flag => !flag)}
        >
          {isDrawerOpen ? <Menu02Icon /> : <Menu01Icon />}
        </IconButton>
      </Stack>

      <Drawer
        anchor="right"
        open={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
        sx={{
          display: { md: 'none' },
        }}
      >
        <Box sx={{ width: 250 }} role="presentation" onClick={() => setIsDrawerOpen(false)}>
          <List>
            <ListItem>
              <ListItemButton href={PublicRoutes.aboutUs}>
                <ListItemText primary="About Us" />
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton href={PublicRoutes.login}>
                <ListItemText primary="Get Started" />
              </ListItemButton>
            </ListItem>
          </List>
        </Box>
      </Drawer>
    </Grid2>
  );
};

export default NavSection;
