import { InputLabelProps, Typography } from '@mui/material';
import { Theme } from '@mui/material/styles';

const InputLabel: React.FC<InputLabelProps> = props => (
  <Typography {...props} component="label" variant="h5" mb={0.75} />
);

const HelperText: React.FC = props => {
  const {
    // @ts-ignore
    ownerState: { error },
  } = props;

  return (
    <Typography variant="body3" mt={0.75} color={error ? 'error' : 'text.secondary'} {...props} />
  );
};

export default function OverridesSelect(theme: Theme) {
  return {
    MuiSelect: {
      defaultProps: {
        variant: 'outlined',
        color: 'secondary',
        fullWidth: true,
        displayEmpty: true,
        slots: {
          inputLabel: InputLabel,
          formHelperText: HelperText,
        },
      },
      styleOverrides: {
        root: {
          '& legend > span.notranslate': {
            opacity: 0,
          },

          '& legend > span:not(.notranslate)': {
            display: 'none',
          },

          '& .MuiSelect-select em': {
            color: theme.palette.text.disabled,
            fontStyle: 'normal',
          },
        },
      },
    },
  };
}
